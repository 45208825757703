import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FirstColumn from './FirstColumn'
import ThirdColumn from './ThirdColumn'
import SecondColumn from './SecondColumn'

const Footer = () => {
    return (
        <div className='footer'>
            <Row>
                <Col xs={12} md={8} className='first-group first-column'>
                    <FirstColumn />
                </Col>
                <Col xs={12} md={4} className='second-group'> 
                    <Row>
                    <Col xs={6} md={5} className='second-column'>
                        <SecondColumn />
                    </Col>
                    <Col xs={6} md={7}>
                        <ThirdColumn />
                    </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Footer
