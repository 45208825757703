import React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { goToCategoryContent } from '../../helper/Helper'

const MenuNavButtonIdol = () => {

    const siteCurrentPage = useSelector(state => state.site.currentPage)
    const history = useHistory();
    
    const goToIdolMenu = () => {
        goToCategoryContent(history, "idol")
    }

    return (
        <div className={ siteCurrentPage === 'idol' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'} onClick={goToIdolMenu}>
            <div className='nav-bar-text-menu-button-name'>
                ไอดอล
            </div>
        </div>
    )
}

export default MenuNavButtonIdol
