import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { emailLogin, getProfile, sendVerifyEmail } from '../../api'
import { firebaseLogEvent, handleProcessAfterLoginSuccess, hideLoading, showLoading } from '../../helper/Helper'
import { useTranslation } from 'react-i18next'
import { Col, Modal, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { useHistory } from 'react-router-dom'
import SplitLine from '../../components/login/SplitLine'
import FacebookLoginButton from '../../components/login/FacebookLoginButton'
import AppleLoginButton from '../../components/login/AppleLoginButton'
import TermAndPrivacy from '../../components/login/TermAndPrivacy'

const LoginForm = ({dispatch, show}) => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")
    const siteCurrentPage = useSelector(state => state.site.currentPage)
    const history = useHistory();

    const handleOnCloseButtonClick = () => {
        handleClose()
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))
    }

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose();
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse)

                //--- mockup data
                // hideLoading();
                // alert("Please check your email and click a link to verify your email address.")

    }

    

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        // handleClose()
        alert(response.message.message)
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))

    }


    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }


    const handleEmailLoginResponse = async (response) => {

        hideLoading()

        if( response.error === false ){
            firebaseLogEvent('login', { method: "Email"})
            dispatch(loginModalSetHidden(true))
            window.location.reload();
            // // //getprofile เพื่ออัพเดตข้อมูลส่วนตัวให้เป็นข้อมูลล่าสุด
            //     getProfile().then( (res) => {
            //         handleProcessAfterLoginSuccess(res, history, siteCurrentPage); //เมื่ออัพเดต profile เสร็จแล้ว ให้ redirect ไปหน้าต่างๆในฟังก์ชันนี้
            //     });

            // // setModalTextTitleSendEmail(response.message.title)
            // setModalTextTitleSendEmail("Email sent")
            // // setModalTextDetailSendEmail(response.message.message)
            // setModalTextDetailSendEmail("We have sent you an email. Please check your email and click the link to verify your email address.")
            // setModalButton1SendEmail('OK')
            // // setModalButton2SendEmail(response.message.button)
            // setModalButton2SendEmail("Resent Email")
            // setVerifyEmailToken(response.result.verifyEmailToken)
            // setShowModal(true)
        }else{
            if(response.error_code === 122) {
                setModalTextTitleSendEmail(response.message.title)
                setModalTextDetailSendEmail(response.message.message)
                setModalButton1SendEmail('OK')
                setModalButton2SendEmail(response.message.button)
                setVerifyEmailToken(response.result.verifyEmailToken)
                setShowModal(true)
            } else {
            
                alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
            }
        }

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        showLoading()
        emailLogin(username, password).then(handleEmailLoginResponse)

    }

    return (
        <div>
        <form className='login-modal-form' hidden={!show} onSubmit={handleOnSubmit}>
            <div className='login-modal-form-header'>
                <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>Back</span>
                <h3 className='text-center'>Sign In</h3>
            </div>
            <div className='login-modal-form-body text-center' style={{paddingBottom: "0px"}}>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Email" onChange={handleUsernameChange} required/>
                    <input type="password" className="form-control" placeholder="Password" onChange={handlePasswordChange} required/>
                    <button className='btn login-modal-form-button mb-2'>Sign In</button>    
                    <div className='font-size-90 text-center text-orange'>
                        <span className='cursor-pointer' onClick={() => dispatch(loginModalSetCurrentTab('forget-password'))}>Forgot Password ?</span>
                    </div>
                    
                    
                </div>
            </div>
        </form>
            <div className="login-modal-form-body text-center" hidden={!show} style={{paddingTop: "0px"}}> 
            <SplitLine /> 
            <Row className='mb-2'>
                <Col className='mb-3'>
                    <FacebookLoginButton />
                </Col>
                <div className='w-100'></div>
                <Col>
                    <AppleLoginButton />
                </Col>
            </Row>
            <TermAndPrivacy />
            </div>


            <Modal className='' show={showModal} onHide={handleClose}>
                <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
                    <Modal.Header  style={{color: 'white', borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <ModalCloseButton onClick={handleClose}/> */}
                        <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>

                        {modalTextDetailSendEmail}
                        
                    </Modal.Body>
                    <Modal.Footer style={{ borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
                        <Button className='btn btn-outline' style={{color: 'var(--color-orange)', backgroundColor: 'RGB(63,63,63)', borderColor: 'var(--color-orange)', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
                        {modalButton2SendEmail}
                        </Button>
                        <Button variant="primary" style={{backgroundColor: 'var(--color-orange)', borderColor: 'var(--color-orange)', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
                        {modalButton1SendEmail}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>


        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)

