import React from 'react'

const MenuButton = ({svgComponent, image, name, className, onClick = () => {}}) => {
    return (
        <div className='row' onClick={onClick}>
            <div className={className ? 'col nav-bar-menu-button-icon ' + className : 'col nav-bar-menu-button-icon'}>
                {
                    svgComponent
                    ?
                        svgComponent
                    :
                        <div className='nav-bar-menu-button-icon-image' style={{'backgroundImage': 'url("'+ image +'")'}}></div>
                }
            </div>
            <div className="w-100"></div>
            <div className='col nav-bar-menu-button-name'>
                {name}
            </div>
        </div>
    )
}

export default MenuButton
