import React, { useEffect, useState } from 'react'
import {Suspense} from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../images/login-modal/icon/icon_mail2.png'



const SignUpWithEmailButton = () => {


    const { t , i18n} = useTranslation('trans');

    return (
        <Suspense fallback={<div>Loading...</div>}>
                <div className='btn ' id="dropdown" style={{color: "white", background: "rgba(0,0,0,0.3)" , borderRadius:"5px", borderColor: "white", paddingLeft: "10px", paddingRight: "10px", paddingTop: "8px", paddingBottom: "8px", fontSize: "16px", width: "100%"}}>
                <img className='' src={Icon} alt='' style={{width: "20px" , marginRight: "5px", marginBottom: "2px"}}/>  
                    <label>Create new account with Email</label></div>
        </Suspense>
    )
}

export default SignUpWithEmailButton
