import 'antd/dist/antd.css';
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";
import '../App.scss';
import '../App.css';
import 'video.js/dist/video-js.css'
import { useEffect, useState } from 'react';
import Home from '../containers/home/Home';
import Search from '../containers/search/Search';
import VideoPage from '../containers/video/VideoPage';
import ChromeCastControlBar from '../containers/chromecast/ChromeCastControlBar';
import {Switch, Route, BrowserRouter as Router, Redirect} from 'react-router-dom'
import React from 'react'
import CategoryPage from './category/CategoryPage';
import SeeAllPage from './seeall/SeeAllPage'
import Footer from './footer/Footer';
import NavBar from '../containers/navbar/NavBar';
import CharacterPage from './character/CharacterPage';
import {connect, useSelector} from 'react-redux'
import {guestLogin, getProfile, checkWebCredentialExist, receiptValidator, checkMaintenance} from '../api'
import LoginModal from '../containers/login/LoginModal';
import { siteSetIsAvailable, userSetProfile } from '../actions';
import { alertErrorByCode, convertUnderscoreObjectKeyToCamelCaseObjectKey } from '../helper/Helper';
import AccountModal from '../containers/account/AccountModal';
import FlixerPlusPage from '../containers/flixerplus/FlixerPlusPage';
import HandePageChange from '../containers/helper/HandePageChange';
import PremiumModal from '../containers/premium/PremiumModal';
import RedeemPage from '../containers/redeem/RedeemPage';
import Loading from './global/Loading';
import ServiceUnavailable from './serviceunavailable/ServiceUnavailable';
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet';
import FormEmailWithFacebook from '../containers/login/FormEmailWithFacebook';
import HomeAll from '../containers/home/HomeAll';

function App( { dispatch } ) {

    const [cookies, setCookie] = useCookies(['userSettings']);

    const [isProfileLoaded, setIsProfileLoaded] = useState(false)

    const isSiteAvailable = useSelector(state => state.site.isAvailable.status)

    useEffect( () => {
        
        // ทุกครั้งที่เปิดหรือ refresh หน้าเว็บ จะทำการเรียก profile ก่อนเสมอเพื่อเช็ค user ก่อนโหลดหน้าเว็บ
        async function process() {

            await checkMaintenanceProcess()
            checkUserSettingsCookiesAndInitialIfNotExsists()
            updateUserSettingsCookiesExpireDate()

            try {
                let checkWebCredentialExistResponse = await checkWebCredentialExist()

                // กรณีที่ยังไม่มี Credentail
                if( checkWebCredentialExistResponse.result === false ){
                    await guestLogin();
                }

                let getProfileResponse = await getProfile()

                // console.log("profile", getProfileResponse);

                if( getProfileResponse.error === false ){

                    if( getProfileResponse.user_type && getProfileResponse.user_type !== 'guest'){
                        await receiptValidator()
                    }

                    let profileResult = convertUnderscoreObjectKeyToCamelCaseObjectKey(getProfileResponse.result)

                    dispatch(userSetProfile(profileResult))
                    setIsProfileLoaded(true)
                }else{
                    alertErrorByCode("500")
                }

            } catch(e) {
                // สำหรับ Handle Promise Reject 
            }
            
        }
    
        process()

    }, [])

     const checkMaintenanceProcess = async () => {
        let checkMaintenanceResponse =  await checkMaintenance()

        let statusMaintenance = checkMaintenanceResponse.result.isMaintenance;
        let isErrorStatus = checkMaintenanceResponse.error;

        if(isErrorStatus) {
            alert("Title: " + checkMaintenanceResponse.message.title + "\nMessage: " + checkMaintenanceResponse.message.message + "\n" )
        }

        if(statusMaintenance) {
            dispatch(siteSetIsAvailable("Our service is currently unavailable. We apologize for any inconvenience. Please check back later."))
            dispatch(siteSetIsAvailable(false))
        }
    }


    // สำหรับดูว่ามี Cookies UserSettings อยู่หรือไม่ ถ้ายังไม่มีจะให้ทำการ Initial ค่า Default 
    const checkUserSettingsCookiesAndInitialIfNotExsists = () => {
        let userSettings = cookies.userSettings
        if( typeof userSettings === 'undefined' ){
            userSettings = window.flixer.userSettingsDefault
            setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge })
        }
    }

    // สำหรับอัพเดท ExpireDate ของ Cookies UserSettings เนื่องจากบาง browser จะไม่รองรับ maxAge ยาวถึง 1 ปี เช่น Safari จะ limit ไว้แค่ 7 วัน
    // จึงต้องทำการอัพเดท ExpireDate ทุกครั้งที่มีการเข้าเว็บ
    const updateUserSettingsCookiesExpireDate = () => {
        let userSettings = cookies.userSettings
        // กรณีที่พึ่ง Initial จาก function checkUserSettingsCookiesAndInitialIfNotExsists() ค่า userSettings ตรงนี้จะยังเป็น undefined อยู่
        if( typeof userSettings !== 'undefined' ){
            // ในส่วนนี้จะเป็นส่วนของการ UpdateVersion Cookies เช่นว่ามีบางอย่างเปลี่ยนแปลงและต้องทำการอัพเดทค่านั้นๆ ก็จะทำการตรวจสอบและอัพเดทตรงนี้
            // กรณีที่ Cookie version มีค่าไม่เท่ากับ version ปัจจุบัน จะต้องทำการอัพเดทตามที่มีการเปลี่ยนแปลง
            if( userSettings.version !== window.flixer.userSettingsDefault.version ){
                // version 2 มีแก้ไขเรื่อง set backgroundOpacity ให้เป็น 0
                userSettings.style.backgroundOpacity = '0'
                userSettings.version = window.flixer.userSettingsDefault.version
                setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge })
            }else{
                setCookie('userSettings', JSON.stringify(userSettings), { path: '/', maxAge: window.flixer.userSettingsCookiesMaxAge })
            }
        }
    }


    return (
            // ตรวจสอบว่าเป็น IP จากประเทศไทยใช่หรือไม่ ถ้าไม่ใช่จะไปหน้า service unavailable
            isSiteAvailable
            ?
                isProfileLoaded  
                ?
                    <div>
                        <Helmet>
                            <title>FLIXER - ฟลิกเซอร์ รวมความบันเทิงจากญี่ปุ่น ซีรีส์ญี่ปุ่น อุลตร้าแมน มาสค์ไรเดอร์ อนิเมะ</title>
                            <meta name="description" content="ดูก่อนใคร ตอนใหม่ล่าสุด อุลตร้าแมน มาสค์ไรเดอร์ อนิเมะซับไทย อนิเมะพากย์ไทย การ์ตูนออนไลน์ ซีรีส์ญี่ปุ่น เจดราม่า J-Drama มูฟวี่" />
                        </Helmet>
                        <div className='body-content'>
                        <Router>
                            <HandePageChange />
                            <NavBar />
                            <Switch>
                                <Route exact path={window.flixer.homeUrl + "/"}>
                                    {/* <Home /> */}
                                    <NavBar />
                                    <HomeAll />
                                </Route>
                                <Route exact path={window.flixer.homeUrl + "/home-streaming"}>
                                    <Home />
                                </Route>
                                <Route exact path={window.flixer.homeUrl + "/flixer-plus"}>
                                    <FlixerPlusPage />
                                </Route>
                                <Route exact path={window.flixer.homeUrl + "/search"}>
                                    <Search />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/video/:id/:episode?"]}>
                                    <VideoPage />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/category/:slug"]}>
                                    <CategoryPage />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/see-all/:id"]}>
                                    <SeeAllPage />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/character/:id"]}>
                                    <CharacterPage />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/redeem"]}>
                                    <RedeemPage />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/otherpackages"]}>
                                    <Home />
                                </Route>
                                <Route path={[window.flixer.homeUrl + "/accounts"]}>
                                    <Home />
                                </Route>
                                <Route>
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                            <ChromeCastControlBar /> 
                            <Loading />
                            <LoginModal />
                            <AccountModal />
                            <PremiumModal />
                            {/* <FormEmailWithFacebook/> */}
                        </Router>
                        </div>
                        <Footer />
                    </div>
                :
                    <div className='container'>
                    </div>
            :
            <div className='body-content-service-unavailable'>
                <ServiceUnavailable />
            </div>

    );
}


const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)