import React from 'react'
import { Row,Col } from 'react-bootstrap'

const RedeemPrivacyPolicy = () => {
    return (
        <Row className='redeem-privacy-policy'>
            <Col className='text-center'>
                <a className='font-size-85 text-secondary-color' target='_blank' href='/privacy-policy'>เงื่อนไขและนโยบายความเป็นส่วนตัว</a>
            </Col>
        </Row>
    )
}

export default RedeemPrivacyPolicy
