import React, { useEffect, useState } from 'react'
import { callApiRentalList, removeAccount } from '../../api'
import AccountModalHeader from './AccountModalHeader'
import RentalContentList from './RentalContentList'
import { useDispatch, useSelector } from 'react-redux'
import { accountModalSetCurrentTab } from '../../actions'
import NoRentalList from './NoRentalList'
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../helper/Helper'

const ManageAccount = () => {

    const dispatch = useDispatch()

    const { t , i18n} = useTranslation('trans');

    const [loaded, setLoaded] = useState(false)

    const userIsPremium = useSelector(state => state.user.isPremium)
    const userFacebookId = useSelector(state => state.user.profile.fbid)
    const userEmail = useSelector(state => state.user.profile.email)

    // const [rentalList, setRentalList] = useState([])

    useEffect( () => {
        // callApiRentalList().then(handleRentalListResponse)
    }, [])

    const handleRemoveAccountResponse = (response) => {
        alert("Success\nYour account has been deactivated and permanently deleted.");
        window.location.reload()
    }

    const handleOnClickDeleteAccount = () => {
        if (userIsPremium) {
            alert("Unable to delete\n\nYou may still join the subscription or your subscription has not expired. To delete your account, please cancel your subscription, let your subscription expire, and then delete your account again.");
        } else {
            let confirm = window.confirm("Before deleting Your Account \n\nIf you delete your account, all of your personal information will be deleted and you will not be able to login again.\n\nHowever, you must cancel your subscription in order to delete your account.");
            if (confirm) {
                removeAccount().then(handleRemoveAccountResponse)
            } else {
                
            }
        }
   
    }

    return (
        <div>
            <AccountModalHeader title="Delete Account" haveBackButton={true} backButtonFunction={() => dispatch(accountModalSetCurrentTab(''))}/>
            <div className='account-modal-list' style={{color : "rgb(255,50,50)", marginTop: "30px",cursor: "pointer" }} onClick={() => handleOnClickDeleteAccount()}>Delete Account</div>
            { (!validateEmail(userEmail) && userFacebookId != null && userFacebookId !== '') ?
            <label style={{marginLeft: '15px', fontSize: '12px', color: 'gray'}}>{userFacebookId}</label>
            : ''
            }
            
        </div>
    )
}

export default ManageAccount
