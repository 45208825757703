export const redeemSetFinish = isFinish => ({
    type: 'REDEEM_SET_FINISH',
    isFinish: isFinish
})

export const redeemSetFinishMessage = message => ({
    type: 'REDEEM_SET_FINISH_MESSAGE',
    message
})


export const redeemSetFinishMessageProductPeriodInDay = periodInDay => ({
    type: 'REDEEM_SET_FINISH_MESSAGE_PRODUCT_PERIOD_IN_DAY',
    periodInDay
})
