import React, {useState, useEffect} from 'react'
import {ReactComponent as SearchIcon} from '../../images/navbar/search-icon.svg'
import MenuButton from '../../components/navbar/MenuButton'
import {getSearchList} from '../../api'
import { useHistory,useLocation } from 'react-router-dom';
import {goToHomePage, search, isSearchPage} from '../../helper/Helper'
import { connect } from "react-redux";
import {homeSetSearchString, homeSetSearchList} from '../../actions'


// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const MobileSearchBar = ({homeSetSearchString, homeSetSearchList}) => {

    let query = useQuery()

    const root = document.documentElement;
    
    const history = useHistory()
    const location = useLocation()

    const [typingTimeoutId, setTypingTimeoutId] = useState(null)
    const [active, setActive] = useState(false)

    useEffect( () => {

        let searchQueryString = getSearchQueryString()
        if( isSearchPage(location.pathname) && !searchQueryString.trim() ){
            goToHomePage(history)
        }else if(!searchQueryString.trim()){
            
        }else{
            getSearchList(searchQueryString).then( (response) => handleGetSearchListResponse(response) )
        }
    },[])


  
    useEffect(() => {
        // กรณีเปลี่ยนเป็นหน้าที่ไม่ใช่ search จะหุบ search bar
        if( !location.pathname.includes('/search') ){
            setActive(false)
        }
    }, [location.pathname]);


    useEffect(() => {
        if( active ){
            root.style.setProperty('--body-content-padding-top', "55px");
        }else{
            root.style.setProperty('--body-content-padding-top', "0px");
        }
    },[active])



    const handleSearchOnChange = ( value ) => {
        
        // if( !value.trim() ){
        //     homeSetSearchString(value)
        //     goToHomePage(history)
        // }else{
        // if(value !== '') {
            homeSetSearchString(value)
            search(history, value)
    
            if( typingTimeoutId ){
                clearTimeout(typingTimeoutId);
            }
    
            let timeoutId = setTimeout(function () {
                getSearchList(value).then( (response) => handleGetSearchListResponse(response) );
            }, 1000);
    
            setTypingTimeoutId(timeoutId);
        // } else {
        //     homeSetSearchString(value)
        //     search(history, value)
        //     homeSetSearchList([]);
        // }
        // }
    };

    const handleGetSearchListResponse = (response) => {
        let searchList = response.result[0].data
        if( searchList && searchList.length > 0){
            homeSetSearchList(searchList)
        }else{
            homeSetSearchList([]);
        }
    };

    const getSearchQueryString = () => {
        if( query.get("q") != null ){
            return query.get("q");
        }else{
            return '';
        }
    }


    const handleOnSearchIconClick = () => {
        setActive(!active)
    }

    return (
        <div className='nav-bar-menu-button'>
            <MenuButton className='account-menu-button' svgComponent={<SearchIcon />} name='Search' onClick={handleOnSearchIconClick}/>
            <div className={'mobile-search-bar' + (active ? ' active' : '')}>
                <div>
                    <input className='d-inline search-input form-control' placeholder='Search' type='text' value={getSearchQueryString()} onChange={ e => handleSearchOnChange(e.target.value) }/>
                    <div className="search-icon"><i></i></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    homeSetSearchString: (searchString) => dispatch(homeSetSearchString(searchString)), 
    homeSetSearchList: (searchList) => dispatch(homeSetSearchList(searchList))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileSearchBar)