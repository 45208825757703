// { code: description, }

window.flixer.ERROR = {
    '500': 'เกิดข้อผิดพลาด',
    '501': 'เกิดข้อผิดพลาด', // เกิดข้อผิดพลาดจาก API request error
    '0001': 'Email address required.',
    '0002': 'เกิดข้อผิดพลาด: %s',
    '0003': 'เกิดข้อผิดพลาดในขั้นตอนการชำระเงิน: หากชำระเงินผ่านช่องทาง บัตรเดบิต/บัตรเครดิต สำเร็จแล้ว โปรดติดต่อฝ่ายประสานงานลูกค้า',
    '0004': 'ยกเลิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
    '0005': 'Title: Usage Limit\nMessage: our account has exceeded the maximum number of viewer at this time.'
}