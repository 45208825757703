import React from 'react'
import ButtonBadgeSilver from '../../images/premium-modal/button-badge-silver.png'

const TopupButton = ({ firstLine, onClick }) => {
    return (
        <button className='premium-modal-secondary-button' style={{height: "100%"}} onClick={onClick}>
            <span  className='second-line-text' >{firstLine}</span><br/>
        </button>
    )
}

export default TopupButton
