import React from 'react'
import MediaLink from '../../containers/global/MediaLink'
import DesktopSampleImage from '../../images/slideshow/slideshow-desktop-sample.png'
import MobileSampleImage from '../../images/slideshow/slideshow-mobile-sample.png'
import ImageShadowDesktop from './../../images/slideshow/shadow-desktop.png'
import ImageShadowMobile from './../../images/slideshow/shadow-mobile.png'
import {Row, Col} from 'react-bootstrap'
import PremiumIcon from './../../images/slideshow/premiumbox.png'
import LikeButton from './LikeButton';

const SlideshowContent = ({details, mode}) => {

    
    const handleLikeDisLikeOnClick = (likeStatus) => {
        //ในหน้านี้ปุ่ม like ยังไม่ได้ทำอะไร
    }

    if( details.link_type === 'movie' ){
        return (
            <MediaLink mediaId={details.id} type={"mediaBannerSlide"}>
                <div className='slideshow-content' style={{position: "relative"}}>

                    <img className='w-100 cursor-pointer slideshow-content-image' src={mode === 'desktop' ? details.web_banner_url : details.banner_url} alt='' loading='lazy'/>
               
                    <img className='w-100 cursor-po30inter slideshow-content-sample-image' src={mode === 'desktop' ? DesktopSampleImage : MobileSampleImage} alt=''/>

                   
                    {
                        mode === 'desktop' ? 
                        <img className='w-100 cursor-pointer slideshow-content-image' src={ImageShadowDesktop} alt='' loading='lazy'/>
                        : 
                        <img className='w-100 cursor-pointer slideshow-content-image' src={ImageShadowMobile} alt='' loading='lazy'/>

                    }
                        

                    <div className="slideshow-content-detail">
                        <Row style={{margin: "0dp"}}>
                            <Col xs={12} md={9} className=''>
                                {details.premium === true ? <img className='img-banner-premium' src={PremiumIcon} alt=""/> : null}
                                
                                <LikeButton likeCount={details.user_like} likeStatus={1} handleOnClick={handleLikeDisLikeOnClick} />
                                {/* <Row>
                                    <Col className='tag-slide-banner'>
                                        {details.tags.map((tagName,position) => (  
                                            <label className='banner-tag-content' key={position}>{tagName}</label>
                                        ))}  
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        <div className='detail-text-banner'>
                                            {details.description}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <MediaLink mediaId={details.id} type={"watchNowButtonBannerSlide"}>
                            <div className='watch-now-content-button'>
                                <label className='text-watch-now'>▶  Watch Now</label>
                            </div>
                        </MediaLink>
                    </div>
                </div>
            </MediaLink>
        )
    }else if( details.link_type === 'browser' ){
        return (
            <div className='slideshow-content' onClick={() => window.open(details.link_url)}>
                <img className='w-100 cursor-pointer slideshow-content-image' src={mode === 'desktop' ? details.web_banner_url : details.banner_url} alt='' loading='lazy'/>
                <img className='w-100 cursor-pointer slideshow-content-sample-image' src={mode === 'desktop' ? DesktopSampleImage : MobileSampleImage} alt=''/>
            </div>
        )
    }else{
        return (
            <div className='slideshow-content'>
                <img className='w-100 cursor-pointer slideshow-content-image' src={mode === 'desktop' ? details.web_banner_url : details.banner_url} alt='' loading='lazy'/>
                <img className='w-100 cursor-pointer slideshow-content-sample-image' src={mode === 'desktop' ? DesktopSampleImage : MobileSampleImage} alt=''/>
            </div>
        )
    }
}

export default SlideshowContent
