import React from 'react'

const SplitLine = () => {
    return (
        <div className="split-line">
            <label className="label-or">OR</label>
        </div>
    )
}

export default SplitLine
