import React from 'react'
import ButtonBadgeSilver from '../../images/premium-modal/button-badge-silver.png'
import ButtonBadgeGold from '../../images/premium-modal/button-badge-gold.png'

const ThirdButton = ({ firstLine, secondLine, thirdLine, bgColor, badgePlus, onClick }) => {
    return (
        <button className='premium-modal-third-button' onClick={onClick} style={{fontSize: '16px', backgroundColor: bgColor, borderColor: bgColor, lineHeight: '100%' , height: '65px'}}>
            
            <span style={{fontSize: '13px'}} className='first-line-text'>{firstLine}</span><br/>
            <span style={{fontSize: '13px'}} className='second-line-text'>{secondLine}</span><br/>
            <span style={{fontSize: '12px'}} className='second-line-text'>{thirdLine}</span>
            { (badgePlus === true) ?
                <img className='premium-modal-secondary-button-badge' src={ButtonBadgeGold} alt='' />
                :
                <img className='premium-modal-secondary-button-badge' src={ButtonBadgeSilver} alt='' />
            }
        </button>
    )
}

export default ThirdButton
