import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden, premiumModalRefresh, premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import FlixerLogo from '../../images/premium-modal/logo.png'
import InformationIcon1 from '../../images/premium-modal/information-icon1.png'
import InformationIcon2 from '../../images/premium-modal/information-icon2.png'
import InformationIcon3 from '../../images/premium-modal/information-icon3.png'
import InformationIcon4 from '../../images/premium-modal/information-icon4.png'
import { alertErrorByCode, firebaseLogEvent, hideLoading, isUserPremiumStatusActive, showLoading } from '../../helper/Helper'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { callApiSubscriptionList, callApiSubscriptionPurchase } from '../../api';
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'
import PremiumButton from './PremiumButton'
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper'
import CancelButton from './CancelButton'
import TopupForm from './TopupForm'
import TopupButton from './TopupButton'
import ThirdButton from './ThirdButton'
import ExpireDateTopupForm from './ExpireDateTopupForm'

const PremiumModal = ({premiumModal}) => {
    const [ subscription1ButtonFirstLineDescription    , setSubscription1ButtonFirstLineDescription ]       = useState('')
    const [ subscription1ButtonSecondLineDescription   , setSubscription1ButtonSecondLineDescription ]      = useState('')
    const [ subscription1ButtonProductId               , setSubscription1ButtonProductId ]                  = useState('')
    const [ subscription1ButtonProductPrice            , setSubscription1ButtonProductPrice ]               = useState('')

    
    const [ subscription2ButtonFirstLineDescription    , setSubscription2ButtonFirstLineDescription ]       = useState('')
    const [ subscription2ButtonSecondLineDescription   , setSubscription2ButtonSecondLineDescription ]      = useState('')
    const [ subscription2ButtonProductId               , setSubscription2ButtonProductId ]                  = useState('')
    const [ subscription2ButtonProductPrice            , setSubscription2ButtonProductPrice ]               = useState('')

    const [ subscription3ButtonFirstLineDescription    , setSubscription3ButtonFirstLineDescription ]       = useState('')
    const [ subscription3ButtonSecondLineDescription   , setSubscription3ButtonSecondLineDescription ]      = useState('')
    const [ subscription3ButtonProductId               , setSubscription3ButtonProductId ]                  = useState('')
    const [ subscription3ButtonProductPrice            , setSubscription3ButtonProductPrice ]               = useState('')

    const [ subscription4ButtonFirstLineDescription    , setSubscription4ButtonFirstLineDescription ]       = useState('')
    const [ subscription4ButtonSecondLineDescription   , setSubscription4ButtonSecondLineDescription ]      = useState('')
    const [ subscription4ButtonThirdLineDescription   , setSubscription4ButtonThirdLineDescription ]      = useState('')
    const [ subscription4ButtonProductId               , setSubscription4ButtonProductId ]                  = useState('')
    const [ subscription4ButtonProductPrice            , setSubscription4ButtonProductPrice ]               = useState('')

    const [ premiumStatus                     , setPremiumStatus ]                      = useState('')
    const [ premiumButtonFirstLineDescription , setPremiumButtonFirstLineDescription ]  = useState('')
    const [ premiumButtonSecondLineDescription, setPremiumButtonSecondLineDescription ] = useState('')

    const [ canCancel        , setCanCancel ]        = useState(true)
    const [ cancelButtonText , setCancelButtonText ] = useState('ยกเลิกการเป็นสมาชิก')

    const show = useSelector(state => state.premiumModal.show)
    const refresh = useSelector(state => state.premiumModal.refresh)
    const currentTab = useSelector(state => state.premiumModal.currentTab)
    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const userValid = useSelector(state => state.user.userValid)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(premiumModalSetShow(false))
    }

    useEffect(() => {
        dispatch(premiumModalSetCurrentTab(''))
        
    }, [])

    useEffect(() => {
        if(show){
            firebaseLogEvent('page_view',{
                page_title: 'Premium Modal',
                page_location: window.location.origin + '/modal/premium',
                page_path: '/modal/premium'
            })

            // callApiPurchaseDateList();
            // callApiTopupBuy();

            callApiSubscriptionList().then(handleSubscriptionListResponse)
            dispatch(premiumModalRefresh(false))
        }
    }, [show])


    useEffect(() => {
        if(refresh){
            callApiSubscriptionList().then(handleSubscriptionListResponse)
            dispatch(premiumModalRefresh(false))
        }
    }, [refresh])

    const handleSubscriptionListResponse = (response) => {

        let result = response.result

        if( result ){

            let userPremium = result.user_premium

            // กรณีไม่ได้ Login userPremium จะเป็น null
            if( userPremium ){

                setPremiumStatus(userPremium.status)

                // กรณีที่คนไม่มี Sub อยู่ค่า status จะไม่ถูกส่งมา (เป็น undefined)
                if( isUserPremiumStatusActive(userPremium.status) ){
                    // Show premium button
                    setPremiumButtonFirstLineDescription(userPremium.button_description.first_line)
                    setPremiumButtonSecondLineDescription(userPremium.button_description.second_line)

                    // กรณีที่ลูกค้ายังไม่ได้ Cancel Subscription
                    if( !isUserCancelled(userPremium.cancel_information) ){
                        if( userPremium.platform === 'flixer'){
                            // กรณี Subscription จาก Platform เว็บจะสามารถกด Cancel ได้
                            setCanCancel(true)
                            setCancelButtonText('ยกเลิกการเป็นสมาชิก')
                        }else{
                            // กรณี Subscription จาก Platform อื่นจะไม่สามารถกด Cancel ผ่านเว็บได้
                            setCanCancel(false)
                            setCancelButtonText('กรุณาจัดการการสมัครใช้บริการผ่านช่องทาง: ' + userPremium.platform)
                        }
                    }else{
                        // กรณีที่ลูกค้า Cancel Subscription แล้ว
                        setCanCancel(false)
                        setCancelButtonText(getUserCancelledDescription(userPremium.cancel_information))
                    }

                }else{
                    setupSubscriptionButtonsText(result.subscription)
                }

            }else{
                setupSubscriptionButtonsText(result.subscription)
            }

        }
    }

    const isUserCancelled = (cancelInformation) => {
        return cancelInformation.cancel
    }

    const getUserCancelledDescription = (cancelInformation) => {
        return cancelInformation.description
    }

    const setupSubscriptionButtonsText = ( subscriptionList ) => {

        subscriptionList.forEach( (subscriptionDetails) => {
            if( subscriptionDetails.product_id === 'com.flixer.flixer.subscription1' ){
                setSubscription1ButtonFirstLineDescription(subscriptionDetails.description.first_line)
                setSubscription1ButtonSecondLineDescription(subscriptionDetails.description.second_line)
                setSubscription1ButtonProductId(subscriptionDetails.product_id)
                setSubscription1ButtonProductPrice(subscriptionDetails.price)
            }else if( subscriptionDetails.product_id === 'com.flixer.flixer.subscription2' ){
                setSubscription2ButtonFirstLineDescription(subscriptionDetails.description.first_line)
                setSubscription2ButtonSecondLineDescription(subscriptionDetails.description.second_line)
                setSubscription2ButtonProductId(subscriptionDetails.product_id)
                setSubscription2ButtonProductPrice(subscriptionDetails.price)
            }else if( subscriptionDetails.product_id === 'com.flixer.flixer.subscription3' ){
                setSubscription3ButtonFirstLineDescription(subscriptionDetails.description.first_line)
                setSubscription3ButtonSecondLineDescription(subscriptionDetails.description.second_line)
                setSubscription3ButtonProductId(subscriptionDetails.product_id)
                setSubscription3ButtonProductPrice(subscriptionDetails.price)
            }else if (subscriptionDetails.product_id === 'com.flixer.flixer.subscription4') {
                setSubscription4ButtonFirstLineDescription(subscriptionDetails.description.first_line)
                setSubscription4ButtonSecondLineDescription(subscriptionDetails.description.second_line)
                setSubscription4ButtonThirdLineDescription(subscriptionDetails.description.third_line)
                setSubscription4ButtonProductId(subscriptionDetails.product_id)
                setSubscription4ButtonProductPrice(subscriptionDetails.price)
            }
        })

    }

    const handlePurchasedButtonOnClick = ( productId, price ) => {

        if( isUserLoggedIn() ){
            showLoading()
            callApiSubscriptionPurchase(productId, price).then( (response) => {
                if( response.result.response && response.result.response.webPaymentUrl ){
                    window.location.href = response.result.response.webPaymentUrl
                }else{
                    alertErrorByCode(500)
                }
                hideLoading()
            })
        }else{
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }
    }

    const clickButtonTopup = () => {
            dispatch(premiumModalSetCurrentTab('topup'))  
    }

    useEffect(() => {
        // currentTab = "topup"
        if(currentTab === 'topup'){
            firebaseLogEvent('page_view',{
                page_title: 'topup',
                page_location: window.location.origin + '/modal/topup',
                page_path: '/modal/topup'
            })
        } else if('topupExpire') {
            firebaseLogEvent('topup_expire_date',{
                page_title: 'topupExpire',
                page_location: window.location.origin + '/modal/topupExpire',
                page_path: '/modal/topupExpire'
            })
            
        } else {
            firebaseLogEvent('page_view',{
                page_title: 'AAA',
                page_location: window.location.origin + '/modal/premium',
                page_path: '/modal/premium'
            })
        }

    }, [currentTab])

    return (
        <Modal
            show={show}
            onHide={closeModal}
            dialogClassName='premium-modal-container modal-dialog-centered'>
                
            <Modal.Body>
            { (currentTab === '' && platformPremium !== "flixer-topup") ?

                <div className='content'>
                <ModalCloseButton onClick={closeModal}/>
                <Container className='text-center'>
                
                    <Row className='premium-modal-flixer-logo'>
                        <Col>
                            <img src={FlixerLogo} alt=''/>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-center mb-4'>
                        <Row className='premium-modal-information'>
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon1} alt='' />รับชมเนื้อหาใหม่ก่อนใคร
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon2} alt='' />รับชมความละเอียดได้สูงสุดแบบ Full HD
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon3} alt='' />ไม่มีโฆษณาคั่นระหว่างรับชม
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon4} alt='' />สามารถเลือกเสียงและซับไตเติ้ลได้
                            </Col>
                        </Row>
                    </div>


                    
                    <Row className='premium-modal-button-group'>
                        {
                                isUserPremiumStatusActive(premiumStatus)
                            ?
                                <React.Fragment>
                                    <Col md={12}>
                                        <PremiumButton firstLine={premiumButtonFirstLineDescription} secondLine={premiumButtonSecondLineDescription} />
                                    </Col>
                                    <div className='w-100 mb-3' />
                                    <Col>
                                        <CancelButton text={cancelButtonText} canCancel={canCancel} />
                                    </Col>
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <Col md={12}>
                                        <PrimaryButton firstLine={subscription1ButtonFirstLineDescription} secondLine={subscription1ButtonSecondLineDescription} bgColor={'rgb(249 169 128)'} onClick={() => handlePurchasedButtonOnClick( subscription1ButtonProductId, subscription1ButtonProductPrice )} />
                                    </Col>

                                    <div className='w-100 mb-3' />

                                    <Col md={6} sm={6} xs={6} style={{marginTop: "15px"}}>
                                        <PrimaryButton firstLine={subscription3ButtonFirstLineDescription} secondLine={subscription3ButtonSecondLineDescription } bgColor={'rgb(247 148 29)'} badgePlus={true} onClick={() => handlePurchasedButtonOnClick( subscription3ButtonProductId, subscription3ButtonProductPrice )} />
                                    </Col>

                                    <Col md={6} sm={6} xs={6} style={{marginTop: "15px"}}>
                                        <ThirdButton firstLine={subscription4ButtonFirstLineDescription} secondLine={subscription4ButtonSecondLineDescription} thirdLine={subscription4ButtonThirdLineDescription} bgColor={'rgb(241 90 34)'} badgePlus={true} onClick={() => handlePurchasedButtonOnClick( subscription4ButtonProductId, subscription4ButtonProductPrice )} />
                                    </Col>

                                    <div className='w-100 mb-3' />
                                    <Col md={6} sm={6} xs={6}>
                                        <PrimaryButton  firstLine={subscription2ButtonFirstLineDescription} secondLine={subscription2ButtonSecondLineDescription} bgColor={'rgb(214 26 33)'} badgePlus={false} onClick={() => handlePurchasedButtonOnClick( subscription2ButtonProductId, subscription2ButtonProductPrice )} />
                                    </Col>
                                    <Col md={6} sm={6} xs={6}> 
                                        <TopupButton firstLine={'Package อื่นๆ'}  onClick={() => clickButtonTopup()} />
                                    </Col>
                                </React.Fragment>
                        }
                    </Row>


                    <Row>
                        <Col className='premium-modal-bottom-text' style={{marginTop: "20px"}}>
                            เมื่อคุณสมัคร FLIXER PREMIUM ระบบจะทำการตัดเงินผ่านช่องทางการชำระที่คุณเลือกเอาไว้ โดยสามารถยกเลิกการต่ออายุอัตโนมัติได้ตลอดเวลา
                        </Col>
                    </Row>


                    {/* {

                    userValid === false ?

                        <Row>                        
                            <Col className='premium-modal-bottom-text'>
                                <PremiumButton firstLine="ต้องการซื้อวัน" secondLine="You want to purchase date." onClick={() => clickButtonTopup()}/>
                            </Col>

                        </Row>

                      : ""

                    } */}


               
                </Container>

                </div>

                : (currentTab === 'topupExpire' && platformPremium === "flixer-topup") ? <ExpireDateTopupForm/> : <TopupForm/> 
                }

            </Modal.Body>
        </Modal>
    )
}

export default PremiumModal
