import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {chromecastSetMediaId, siteSetisClickFromSlideShow} from '../../actions'
import {goToVideo, hideLoading, showLoading} from '../../helper/Helper'
import { getWoocommercePermalink } from '../../api'

const MediaLink = ({ mediaId, chromeCastSessionState, chromecastSetMediaId, children, type , typeContent}) => {

    const dispatch = useDispatch()

    let history = useHistory();
    const [permalink, setPermalink] = useState("")

    const openInNewTab = (url) => {

        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const getPermalink = function(response) {
        if(response.result.permalink != null && response.result.permalink !== "" ) {
            setPermalink(response.result.permalink);
            window.open(response.result.permalink, '_self', 'noopener,noreferrer');
            
        }

        hideLoading();

    }

    
    const handleOnClick = (event, mediaId, typeContent) => {

        if(typeContent !== 'ebook') {
            if(type === "watchNowButtonBannerSlide") {
                dispatch(siteSetisClickFromSlideShow(true))
            } else {
                dispatch(siteSetisClickFromSlideShow(false))
            }
            
            // กรณีที่เปิด ChromeCast อยู่ จะทำการเล่นหนังผ่าน ChromeCast
            if( chromeCastSessionState === window.flixer.chromecast.sessionState.started || 
                chromeCastSessionState === window.flixer.chromecast.sessionState.resumed
            ){
                chromecastSetMediaId(mediaId);
            }else{
                goToVideo(history, mediaId);
            }
        } else {
            showLoading();
            getWoocommercePermalink(mediaId).then(getPermalink);
        }

        //ให้มันไม่ได้ทำ event ของ parent ที่เกิดขึ้น
        event.stopPropagation()

    }

    return (
        <div onClick={ (e) => handleOnClick(e, mediaId, typeContent) } className='media-link'>
            {children}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    mediaId: ownProps.mediaId,
    episode: ownProps.episode,
    chromeCastSessionState: state.chromecast.sessionState
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    chromecastSetMediaId: (mediaId) => dispatch(chromecastSetMediaId(mediaId))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaLink)
