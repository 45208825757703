import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import AllMediaContentList from '../global/AllMediaContentList'
import { getCharacterList } from '../../api'
import { makeListSupportAllMediaContentListFormat } from '../../helper/Helper'


const CharacterPage = () => {

    const {id} = useParams();

    useEffect(() => {
        getCharacterList(id).then( (res) => {
            handleGetCharacterListResponse(res)
        })
    }, [])

    const [characterList, setCharacterList] = useState([])
    const [characterTitle, setCharacterTitle] = useState([])

    const handleGetCharacterListResponse = ( response ) => {
        let list = makeListSupportAllMediaContentListFormat(response.result.data, 'character');
        setCharacterList(list)
        setCharacterTitle(response.result.title)
    }

    return (
        <div>
            <AllMediaContentList title={characterTitle} list={characterList} description=''/>
        </div>
    )
}

export default CharacterPage
