import React from 'react'
import { useDispatch } from 'react-redux'
import { premiumModalRefresh } from '../../actions'
import { callApiCancelSubscription } from '../../api'
import { alertErrorByCode, hideLoading, showLoading } from '../../helper/Helper'

const CancelButton = ( { text, canCancel } ) => {

    const dispatch = useDispatch()

    const handleOnClick = () => {
        if( canCancel ){

            let confirmResponse = window.confirm("คุณต้องการยกเลิกการเป็นสมาชิกใช่หรือไม่?")

            if (confirmResponse === true) {

                showLoading()
                callApiCancelSubscription().then((response) => {
                    if( response.error === false && response.result ){
                        if( response.result.cancel === 'success' ){
                            dispatch(premiumModalRefresh(true))
                            // premium modal refresh ก่อน ก่อนค่อยขึ้น alert
                            setTimeout( () => {
                                hideLoading()
                                alert("ได้ทำการยกเลิกสำเร็จแล้ว สิทธิ์พรีเมี่ยมจะใช้ได้จนหมดอายุ")
                            }, 1000)
                        }else{
                            hideLoading()
                            alertErrorByCode("0004")
                        }
                    }else{
                        hideLoading()
                        alertErrorByCode("500")
                    }
                    
                })
            }
        }
    }

    return (
        <div className={'premium-modal-cancel-button ' + (canCancel ? "can-cancel" : "can-not-cancel")} onClick={handleOnClick}>
            {text}
        </div>
    )
}

export default CancelButton
