import axios from "axios"
import { siteSetAvailableMessage, siteSetIsAvailable, userSetCanView, userSetIsPremium, userSetLevel, userSetPremiumExpire, userSetPremiumPlatForm, userSetType, userSetValid } from "../actions";
import {guestLogin, refreshToken} from '../api'
import { store } from '../index';
import { alertErrorByCode, consoleErrorHelper, consoleLogHelper, hideLoading } from "./Helper";

export const callGet = (url) => {

    return new Promise( ( resolve, reject ) => { 
        axios({
            method: 'GET',
            url: url,
            timeout: 1000 * 30, // Wait for 30 seconds
            withCredentials: true
        }).then(
            (response) => {

                if( isReceiptValidatorApi(url) ){
                    handleReceiptValidator(response)
                }

                handleResponse(url, response, resolve, 'GET')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })

}

export const callAISGetAPI = (url) => {

    return new Promise( ( resolve, reject ) => { 
        axios({
            method: 'GET',
            url: url,
            withCredentials: true
        }).then(
            (response) => {
                consoleLogHelper(url, response)
                resolve(response.data)
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })

}


export const callPost = (url, data) => {

    let bodyFormData = new FormData();

    Object.keys(data).forEach(key => {
        bodyFormData.append(key, data[key])
    });

    return new Promise( ( resolve, reject ) => { 
        axios({
            method: 'POST',
            url: url,
            withCredentials: true,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        }).then(
            (response) => {
                handleResponse(url, response, resolve, 'POST')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })
}

export const callPostWithoutData = (url) => {

    return new Promise( ( resolve, reject ) => { 
        axios({
            method: 'POST',
            url: url,
            withCredentials: true
        }).then(
            (response) => {
                handleResponse(url, response, resolve, 'POST')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })
}





const handleResponse = ( url, response, resolve, type ) => {
    
    consoleLogHelper(url, response)

    if(isMaintenanceApi(url)) {
        resolve(response.data);
        return;
    }

    if( response && response.data.error ){

        // ดักกรณีที่ไม่ใช่ IP ของประเทศไทย จะเข้าหน้า Service Unavailable
        // error_code 403 คือ ไม่ใช่ IP ของประเทศไทย
        if( response.data.error_code && response.data.error_code === 403 ){
            store.dispatch(siteSetAvailableMessage("Flixer is unavailable in your region. Visit the link below for more information."))
            store.dispatch(siteSetIsAvailable(false))
        }else if( response.data.result.message === "token_expired" ){

            
            // กรณีที่ Token Expire จะทำการเรียก RefreshToken และเรียก API เดิมซ้ำเพื่อให้ได้ response 
            async function process() {
    
                try {
                    await refreshToken()
                    if( type === 'GET'){
                        resolve( await callGet(url))
                    }else if ( type === 'POST'){
                        resolve( await callPost(url))
                    }
                } catch(e) {
                }
                
            }
        
                process()

            
        }else{

            async function handleRefreshTokenExpire() {
                await guestLogin()
                window.location.href = window.flixer.homeUrl + '/'
            }
            
            // กรณีที่ refreshToken ไม่สำเร็จ จะทำการเรียก guestLogin เพื่อ reset user ไม่งั้นจะติด infinity loop
            if( url.includes('/refreshToken') && !response.data.result.refreshed ){
                handleRefreshTokenExpire()
            }else{
                let errorMessage = response.data.message.message ? response.data.message.message : "-";
                consoleErrorHelper("API error.\n\nUrl: "+ url +"\nTitle: " + response.data.message.title + "\nMessage: " + errorMessage)
                resolve(response.data);
            }
            
         
        }

    }else{
        handleUserInformationFromResponse(response)
        resolve(response.data);
    }
    
}


const handleUserInformationFromResponse = (response) => {

    let userLevel   = response.data['user_level'];
    let userType    = response.data['user_type'];
    let canView     = response.data['can_view'];

    if( typeof userLevel !== 'undefined' ){
        store.dispatch(userSetLevel(userLevel))
    }
    if( typeof userType !== 'undefined' ){
        store.dispatch(userSetType(userType))
    }
    if( typeof canView !== 'undefined' ){
        store.dispatch(userSetCanView(canView))
    }else{
        store.dispatch(userSetCanView(0))
    }

}


const handleError = (url, response, reject) => {

    hideLoading();
    reject(response)

    // console.log(response);

    if(isMaintenanceApi(url)) {

        store.dispatch(siteSetAvailableMessage("Our service is currently unavailable. We apologize for any inconvenience. Please check back later."))
        store.dispatch(siteSetIsAvailable(false))
        
    } else {

        consoleLogHelper("Error: " + url, response)
        if(response && response.message === 'Network Error' ){
            // Safari refresh page before request finished
        }else{
            alertErrorByCode("501")
        }

    }
}

const isMaintenanceApi = (url) => {
    return url.match(/\/maintenance$/) !== null
}

const isReceiptValidatorApi = (url) => {
    return url.match(/\/receiptValidator$/) !== null
}

// กรณีเป็น API ReceiptValidator จะต้องทำการดึงค่า Response มา Update ค่า UserPremium
const handleReceiptValidator = (response) => {

    let isPremium = false;

    if( response && !response.data.error ){
        if( response.data.result && response.data.result.receipt_summary ){
            //  ถ้ายังไม่หมดอายุแสดงว่าเป็น Premium อยู่
            if( response.data.result.receipt_summary.expired === false ){
                isPremium = true
            }
        }

        let premiumExpire = response.data.result.receipt_summary.expires_date;

        if(premiumExpire != null && premiumExpire != '') {
            store.dispatch(userSetPremiumExpire(premiumExpire))
        } else {
            store.dispatch(userSetPremiumExpire(0))
        }

        let premiumPlatform = response.data.result.receipt_summary.platform;
        if(premiumPlatform != null) {
                store.dispatch(userSetPremiumPlatForm(premiumPlatform))
        }

        let userValid = response.data.result.receipt_summary.valid;
        if(userValid != null) {
                store.dispatch(userSetValid(userValid))
        }


    }
    
    store.dispatch(userSetIsPremium(isPremium))
    
}
