import React from 'react'
import PremiumIcon from './../../images/navbar/premium.png'
import {useDispatch} from 'react-redux'
import { premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions';

const PremiumButton = () => {

    const dispatch = useDispatch();
   

    const clickButton = () => {
        dispatch(premiumModalSetCurrentTab(''))
        dispatch(premiumModalSetShow(true))
    }

    return (
        <div className='nav-bar-text-menu-button' onClick={() => clickButton()}>
            <div className='nav-bar-text-menu-button-name nav-bar-premium-button'>
                <img className='nav-bar-text-menu-button-icon' src={PremiumIcon} alt='' /> Premium
            </div>
        </div>
    )
}

export default PremiumButton
