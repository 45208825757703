import React, {useCallback, useEffect} from 'react'
import { connect, useSelector } from 'react-redux'
import { Col } from 'react-bootstrap';
import { useResizeDetector} from 'react-resize-detector/build/withPolyfill';
import { loginModalSetCurrentTab, loginModalSetHidden, premiumModalSetShow, siteSetisClickFromSlideShow } from '../../actions';
import Ads from '../../components/ads/Ads';
import { isUserPremium } from '../../helper/ReduxStoreHelper';
import { alertErrorByCode, getRentalPriceFromMediaDetail, getRentalProductIdFromMediaDetail } from '../../helper/Helper';
import RentButton from '../../components/video/RentButton';

const DummyPage = ({ thumbnailUrl, isGuest, dispatch, adsDetails, isCurrentEpisodePremium, mediaDetails, showRentButton, isVideoPageLoaded }) => {

    const root = document.documentElement;

    const onResize = useCallback(( width, height ) => {
        root.style.setProperty('--video-js-height', height + "px");
    }, []);
      
    const { ref } = useResizeDetector({ onResize });

    const isClickFromSlideShow = useSelector(state => state.site.isClickFromSlideShow)

    useEffect(()=>{

    if(isVideoPageLoaded) {
        if(isClickFromSlideShow) {
            dispatch(siteSetisClickFromSlideShow(false))
            if(!showRentButton) {
                handleOnClick()   
            }
            
        }
    }

    },[isVideoPageLoaded])

    const handleOnClick = () => {

        if( isGuest === true ){
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }else{

            if( isCurrentEpisodePremium && isUserPremium() ){
                // กรณีที่หนังเป็น Premium และ User ก็เป็น Premium แสดงว่า User ดูหนังอยู่ในอีกจอนึงอยู่
                alertErrorByCode("0005")
            }else if( isCurrentEpisodePremium && !isUserPremium() ){
                // กรณีที่หนังเป็น Premium แต่ว่า User ไม่ได้เป็น Premium จะ Show หน้า Sub
                dispatch(premiumModalSetShow(true))
            }
            
        }
    }

    return (
        <React.Fragment>
            <Col xs={12} md={9} ref={ref}>
                <div className='dummy-page-video-player-container' style={{"position": "relative"}}>
                    <img className='can-not-view-image' src={thumbnailUrl} alt=''/>
                        {
                            showRentButton
                        ?
                            <RentButton productId={getRentalProductIdFromMediaDetail(mediaDetails)} price={getRentalPriceFromMediaDetail(mediaDetails)} className='mb-3 dummy-page' />
                        :
                            <div className='video-js video-js-dummy' style={{'width': '100%', 'height': 'auto'}} onClick={handleOnClick} >
                                <button className="vjs-big-play-button" type="button" title="Play Video" aria-disabled="false"><span aria-hidden="true" className="vjs-icon-placeholder"></span><span className="vjs-control-text" aria-live="polite">Play Video</span></button>
                            </div>
                        }
                </div>
            </Col>
            <Col xs={12} md={3}>
                <Ads 
                    adUnitId={adsDetails.ads_unit_id}
                    sampleImage={adsDetails.banner_url}
                    width={parseInt(adsDetails.width)}
                    height={parseInt(adsDetails.height)}
                />
            </Col>
        </React.Fragment> 
    )
}

const mapStateToProps = (state, ownProps) => ({
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DummyPage)
