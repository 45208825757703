import React from 'react'
import { isMobileWidth } from '../../helper/Helper';

const Description = ({ text, subtitles, audios }) => {

    return (
        <div className='col-md-12'>
        <div className='video-page-description mb-4'>
            {text}
        </div>
        {(isMobileWidth == false) ? 
<div className='col-md-12'>
    <div className='row'>
        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
            <h4 style={{opacity: "0.4"}}>Subtitles</h4>
            {
                // subtitles.map( (track, index) => {     
                //     return <font className="" key={index} style={{fontSize: '15px'}}>{track.language} </font>;
                // }
                (subtitles != null && subtitles !== "") ?
                    <font className="" style={{fontSize: '15px'}}>{subtitles} </font>
                : 
                    <font className="" style={{fontSize: '15px'}}> - </font>
            }
        </div>
        <div className='col-md-6 col-sm-12' style={{marginBottom: "40px"}}>
            <h4 style={{opacity: "0.4"}}>Audios</h4>
            {
                // audios.map( (track, index) => {     
                //     return <font className="" key={index} style={{fontSize: '15px'}}>{track.language} </font>;
                // })
                (audios != null && audios !== "") ?
                <font className="" style={{fontSize: '15px'}}>{audios} </font>
                : 
                <font className="" style={{fontSize: '15px'}}> - </font>
            }
        </div>
    </div> 
</div>
: 

<div className='col-md-12'>
    <div className='row'>
        <div className='col-md-6 col-sm-6' style={{marginBottom: "40px"}}>
            <h4 style={{opacity: "0.4"}}>Subtitles</h4>
            {
                (subtitles != null && subtitles !== "") ?
                    <font className="" style={{fontSize: '15px'}}>{subtitles} </font>
                : 
                    <font className="" style={{fontSize: '15px'}}> - </font>
            }
        </div>
        <div className='col-md-6 col-sm-6' style={{marginBottom: "40px"}}>
            <h4 style={{opacity: "0.4"}}>Audios</h4>
            {
                (audios != null && audios !== "") ?
                    <font className="" style={{fontSize: '15px'}}>{audios} </font>
                : 
                <font className="" style={{fontSize: '15px'}}> - </font>
            }
        </div>
    </div>
</div>
}
</div>
    )
}

export default Description
