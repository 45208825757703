import React from 'react'
import { useSelector } from 'react-redux'
import FlixerLogo from '../../images/service-unavailable/flixer-logo.png'

const ServiceUnavailable = () => {

    const message = useSelector(state => state.site.isAvailable.message)

    return (
        <div className="service-unavailable-page">
            <div className="service-unavailable-content">
                <img className='service-unavailable-logo' src={FlixerLogo} alt='' />
                <div className='service-unavailable-title'>
                    Service Unavailable
                </div>
                <div className='service-unavailable-information'>
                    {message}
                </div>
                <div className='service-unavailable-terms'>
                    <a className='service-unavailable-terms-terms-of-use' target='_blank' href='/term-of-use'>Terms of Use</a>
                    <a target='_blank' href='/privacy-policy'>Privacy Policy</a>
                </div>
            </div>
        </div>
    )
}

export default ServiceUnavailable