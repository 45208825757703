import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { redeemSetFinish, redeemSetFinishMessage } from '../../actions'
import { goToHomePage } from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import AisRedeemForm from './AisRedeemForm'
import FinishPage from './FinishPage'
import RedeemCodeForm from './RedeemCodeForm'
import RedeemPrivacyPolicy from './RedeemPrivacyPolicy'
import RedeemEbook from './RedeemEbook'

const RedeemPage = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(redeemSetFinishMessage(''))
        dispatch(redeemSetFinish(false))

        if( !isUserLoggedIn() ){
            goToHomePage(history)
        }
            
    }, [])

    const userProfile = useSelector(state => state.user.profile)
    const redeem      = useSelector(state => state.redeem)

    return (
            !redeem.isFinish
        ?
            <div className='redeem-page'>
                <Container>
                    <Row className='redeem-page-header'>
                        <Col>
                            {
                                isUserPremium() 
                                ?
                                <h4><b>ท่านเป็นผู้ใช้ Flixer Premium อยู่แล้ว</b></h4>
                                :
                                ""

                            }
                        </Col>
                        <div className='w-100' />
                        <Col className='text-secondary-light-color'>
                            Flixer Account: {userProfile.email}
                        </Col>
                    </Row>

                    <RedeemCodeForm />
                    <hr/>
                    <AisRedeemForm />
                    {/* <hr/>
                    <RedeemEbook /> */}

                    <RedeemPrivacyPolicy />

                </Container>
            </div>
        :
            <div className='redeem-page redeem-finish-page'>
                <FinishPage message={redeem.finishMessage} periodInDay={redeem.finishMessageProductPeriodInDay}/>
            </div>
        
    )
}

export default RedeemPage
