import React from 'react'
import MediaContent from './MediaContent'
import SeeAllButton from './SeeAllButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import { checkIsMobileWidth } from '../../helper/Helper';
import useClientWidth from '../../hooks/useScreenWidth';
import DragScrollContainer from '../helper/DragScrollContainer'

const MediaListContainer = ({title, lists, shelfId, type}) => {

    const clientWidth = useClientWidth()

    return (
        (lists.length > 0) ?
        <div className='media-list-container' style={{marginLeft: '-40px', 
                                                        marginRight: '-40px', 
                                                        paddingLeft: '50px', 
                                                        paddingRight: '50px', 
                                                        paddingTop: '25px', 
                                                        marginBottom: '25px', 
                                                        backgroundColor: (type === 'ebook') ? '#fff' : ''}}>
            <div className='mb-1'>
                {/* <div className='media-list-container-title d-inline' >{title}</div> */}
                <div className='media-list-container-title d-inline' style={{ color: (type === 'ebook') ? '#000' : ''}}>{title}</div>
                {/* { (type === 'ebook') ? <SeeAllButton shelfId={shelfId} type={type}/> : <SeeAllButton shelfId={shelfId}/> } */}
               <SeeAllButton shelfId={shelfId} type={type}/>
            </div>
            {
                    checkIsMobileWidth(clientWidth)
                ?
                    <DragScrollContainer className='media-list full-width-container-with-start-spacing'>
                        {
                            lists.map( (media) => {
                                return  <MediaContent key={media.id + media.episode} mediaId={media.id} thumbnailUrl={media.thumbnail_url} name={media.name} type={type}/>
                            })
                        }
                    </DragScrollContainer>
                :
                    <Swiper 
                        slidesPerView={'auto'} 
                        slidesPerGroup={4}
                        freeMode={true} 
                        navigation={true}
                        allowTouchMove={false}
                        speed={500}
                        className='horizontal-list media-list full-width-container-with-start-spacing'
                        style={{backgroundColor: (type === 'ebook') ? '#fff' : ''}}
                    >
                        {
                            lists.map( (media) => {
                                return  <SwiperSlide key={media.id + media.episode}><MediaContent  mediaId={media.id} thumbnailUrl={media.thumbnail_url} name={media.name} type={type}/></SwiperSlide>
                            })
                        }
                    </Swiper>
            }
        </div>
        : ''
        
    )

}

export default MediaListContainer
