import React, {useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {getShelf} from '../../api';
import { makeListSupportAllMediaContentListFormat } from '../../helper/Helper';
import AllMediaContentList from '../global/AllMediaContentList'

const SeeAllPage = () => {

    const {id} = useParams()
    const [contentList, setContentList] = useState([])
    const [title, setTitle] = useState('')

    useEffect(() => {
        getShelf(id).then(handleHomePageApi)
    }, [id])

    const handleHomePageApi = function(response){
        let list = makeListSupportAllMediaContentListFormat(response.result.data)
        setContentList(list)
        setTitle(response.result.title)
    }


    return (
        <div>
            <AllMediaContentList title={title} list={contentList} description=''/>
        </div>
    )
}

export default SeeAllPage
