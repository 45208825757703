import React from 'react'
import { Col } from 'react-bootstrap'
import AppStoreBadge from '../../images/app-store-badge.svg'
import GooglePlayBadge from '../../images/google-play-badge.svg'

const ThirdColumn = () => {
    return (
        <div className='float-end'>
            <Col className='mb-2'>
                แอป Flixer
            </Col>
            <div className='w-100'></div>
            <Col>
                <a target='_blank' rel="noreferrer" href='https://apps.apple.com/us/app/flixer-%E0%B8%9F%E0%B8%A5-%E0%B8%81%E0%B9%80%E0%B8%8B%E0%B8%AD%E0%B8%A3/id1287963018?ls=1'>
                    <img className='footer-app-store-badge' width='120px' src={AppStoreBadge} alt=''/>
                </a>
                <a target='_blank' rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.flixer.flixer'>
                    <img className='footer-google-play-badge' height='40px' src={GooglePlayBadge} alt=''/>
                </a>
            </Col>
            <div className='build-version'>{process.env.REACT_APP_ENV} v{process.env.REACT_APP_VERSION}</div>
        </div>
    )
}

export default ThirdColumn
