import React, { useState } from 'react'
import {  goToRedeemPage } from '../../helper/Helper'
import { useHistory } from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper'
import { loginModalSetHidden } from '../../actions'
import { Button, Modal } from 'react-bootstrap'
import iconHomeEbook from '../../images/navbar/ebook_platformIcon.png'
import iconHomeStreaming from '../../images/navbar/video_platformIcon.png'
import iconGift from '../../images/navbar/gift_icon.png'

const RedeemButton = () => {
    
    const siteCurrentPage = useSelector(state => state.site.currentPage)
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const history = useHistory();

    const handleOnClick = () => {
        if( isUserLoggedIn()){
            // setShowModal(true)
            goToRedeemPage(history)
        }else{
            dispatch(loginModalSetHidden(false))
            // setShowModal(true)
        }
    }

    const goToHomeStreaming = () => {
        // goToCategoryContent(history, "anime")
        // window.location.href = "/redeem";
        handleClose();
        if( isUserLoggedIn()){
            goToRedeemPage(history)
        } else {
            dispatch(loginModalSetHidden(false))
        }
    }

    const goToShopEbook = () => {;
        window.open('https://shopdev.flixerapp.com/redeem-code', '_blank');
    }

    return (
        <>
            <div className={ siteCurrentPage === 'redeem' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'} onClick={handleOnClick}>
                <div className='nav-bar-text-menu-button-name'>
                    Redeem
                </div>
            </div>

            {/* <Modal className='' show={showModal} onHide={handleClose} style={{flexDirection: 'unset !important'}} centered>
            <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none', borderRadius: '9px', flexDirection: 'unset'}}>
                <Modal.Body style={{color: 'white', textAlign: 'center', paddingTop: '30px', paddingBottom: '50px'}}>
                <p><img className='nav-bar-flixer-logo' src={iconGift} alt=''  style={{width: '15px', marginLeft: '20px', cursor: 'pointer', paddingBottom: '8px'}} />  โปรดเลือก Platform ที่คุณต้องการ Redeem</p>
                <div style={{display: 'flex', textAlign: 'center', margin: 'auto', width: 'max-content', marginTop: '30px'}}>
                    <div style={{textAlign: 'center' }}>
                        <p><img className='nav-bar-flixer-logo' src={iconHomeStreaming} alt=''  style={{width: '60px', cursor: 'pointer'}} onClick={goToHomeStreaming}/></p>
                        <label>Video</label>
                    </div>
                    <div style={{textAlign: 'center' ,marginLeft: '50px'}}>
                        <p><img className='nav-bar-flixer-logo' src={iconHomeEbook} alt=''  style={{width: '60px', cursor: 'pointer'}} onClick={goToShopEbook}/></p>
                        <label>Ebook</label>
                    </div>
                </div>
                    
                </Modal.Body>
            </div>
            </Modal> */}
        </>
    )
}

export default RedeemButton
