import React, { useEffect, useState } from 'react'
import MediaContent from '../../components/home/MediaContent'

const AllMediaContentList = ({ title, list, description }) => {

    return (
        <div className='all-media-content-container'>
            <div className='title-media-content'>
                <label className='text-title-media-content'>{title}</label>
                {
                    (title != null) ? <label className='text-description-media-content'>{description}</label> : ''

                }
            </div>
            <div className='all-media-content-list'>
                {
                    list.map( (details, index) => {
                        return <MediaContent key={index} mediaId={details.id} name={details.name} thumbnailUrl={details.imageUrl}/>
                    })
                }
            </div>
        </div>
    )
}

export default AllMediaContentList
