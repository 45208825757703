import React , { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { fbLogin, forgetPassword, sendVerifyEmail } from '../../api'
import { firebaseLogEvent, hideLoading, showLoading, validateEmail } from '../../helper/Helper'
import { Button, Modal } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'

const FormEmailWithFacebook = ({show}) => {

    const dispatch = useDispatch()

    const userDataFacebook = useSelector(state => state.loginModal.userData);
    const [data, setData] = useState();

    const [email, setEmail] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirmEmail, setShowModalConfirmEmail] = useState(false);
    const handleCloseConfirmEmail = () => setShowModalConfirmEmail(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleOnCloseButtonClick = () => {
        handleClose()
        dispatch(loginModalSetCurrentTab(''));
    }

    const handleOnClick = () => {
        setShowModalConfirmEmail(true)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if( !validateEmail(email) ) {
            alert("โปรดระบุอีเมลที่ถูกต้อง");
        } else {
            setShowModalConfirmEmail(true);
        }

      }

    const handleConFirmEmailOnClick = () => {
        handleCloseConfirmEmail();

        data.fbemail = email;
        data.shouldVerifyEmail = 1;

        fbLogin(data).then(handleFacebookLoginResponse);
        
    }

    const handleFacebookLoginResponse = async (response) => {

        hideLoading()

        if(response.error_code === 122) {
            setModalTextTitleSendEmail(response.message.title)
            setModalTextDetailSendEmail(response.message.message)
            setModalButton1SendEmail('OK')
            setModalButton2SendEmail(response.message.button)
            setVerifyEmailToken(response.result.verifyEmailToken)
            setShowModal(true)
        } else {
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
        }

    }

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose();
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse);

    }

    

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        alert(response.message.message)
        dispatch(loginModalSetCurrentTab(''));

    }

    useEffect(() => {
        // if (userDataFacebook != null) {
            setData(userDataFacebook);
        // }
    }, [userDataFacebook])

        
    return (
        <>
            <div className='login-modal-form forget-password-form' hidden={!show}>
                <div className='login-modal-form-header'>
                    <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>Cancel</span>
                    <h3 className='text-center'>โปรดตั้งค่าอีเมลของคุณ</h3>
                    {/* <label className='' style={{float: 'right', fontSize: '22px', top: '0px', position: 'absolute', zIndex: '1000', cursor: 'pointer', right: '0',}} onClick={skipFormToLogin}>
                        Skip
                    </label> */}
                </div>
                <div className='login-modal-form-body text-center'>
                    <div className='mb-3'>
                    </div>

                    <div style={{marginBottom: '20px'}}>
                        <h5>เพื่อป้องกันการสูญหายของข้อมูล กรุณาตั้งค่าอีเมลของคุณหลังเริ่มใช้งาน</h5>
                    </div>
                    <form className='' onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Email" onChange={handleEmailChange} required/>
                            <button className='btn login-modal-form-button mb-2' >ตั้งค่าอีเมล</button>    
                        </div>
                    </form>
                </div>
            </div>

            <Modal className='' show={showModal} onHide={handleClose}>
                <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
                    <Modal.Header  style={{color: 'white', borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <ModalCloseButton onClick={handleClose}/> */}
                        <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>

                        {modalTextDetailSendEmail}
                        
                    </Modal.Body>
                    <Modal.Footer style={{ borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
                        <Button className='btn btn-outline' style={{color: 'var(--color-orange)', backgroundColor: 'RGB(63,63,63)', borderColor: 'var(--color-orange)', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
                        {modalButton2SendEmail}
                        </Button>
                        <Button variant="primary" style={{backgroundColor: 'var(--color-orange)', borderColor: 'var(--color-orange)', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
                        {modalButton1SendEmail}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>

            <Modal className='' show={showModalConfirmEmail} onHide={handleCloseConfirmEmail}>
                <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
                    <Modal.Header  style={{color: 'white', borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <ModalCloseButton onClick={handleCloseConfirmEmail}/> */}
                        <Modal.Title>โปรดยืนยันความถูกต้องของที่อยู่อีเมล</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>

                    ระบบจะส่งลิ้งค์สำหรับ Verify Email ไปที่ {email}
                        
                    </Modal.Body>
                    <Modal.Footer style={{ borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
                        <Button className='btn btn-outline' style={{color: 'var(--color-orange)', backgroundColor: 'RGB(63,63,63)', borderColor: 'var(--color-orange)', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleCloseConfirmEmail}>
                        แก้ไขอีเมล
                        </Button>
                        <Button variant="primary" style={{backgroundColor: 'var(--color-orange)', borderColor: 'var(--color-orange)', color: 'black', borderWidth: '1.5px'}} onClick={handleConFirmEmailOnClick}>
                        ยืนยัน
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}

export default FormEmailWithFacebook
