import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden, premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions'
import { callApiTopupBuy, callApiTopupList } from '../../api'
import { getDayLeft, hideLoading, showLoading } from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import Icon from '../../images/modal/close-btn.png'
import BackTopupButton from './BackTopupButton'
import ButtonTopupTranparent from './ButtonTopupTranparent'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import FlixerLogo from '../../images/premium-modal/logo.png'
import FlixerBannerTopup from '../../images/modal/bannertopuppremium.png'
import InformationIcon1 from '../../images/premium-modal/information-icon1.png'
import InformationIcon2 from '../../images/premium-modal/information-icon2.png'
import InformationIcon3 from '../../images/premium-modal/information-icon3.png'
import InformationIcon4 from '../../images/premium-modal/information-icon4.png'
import PremiumButton from './PremiumButton'
import CancelButton from './CancelButton'

const ExpireDateTopupForm = () => {

    const [data, setData] = useState([]);
    const [ dateExpireCount , setDateExpireCount ] = useState('')
    const dispatch = useDispatch()
    const premiumExpire = useSelector(state => state.user.premiumExpire)
    const [ dayLeftExpire , setDayLeftExpire ] = useState(0)
    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const [ statusLoadData , setStatusLoadData ] = useState(false)
    const [ textPremiumExpire , setTextPremiumExpire ] = useState('')


    useEffect(() => {
        showLoading();
        setStatusLoadData(false)
        callApiTopupList().then(handleTopupListResponse)
            
    }, [])

    useEffect(() => {
        
        hideLoading();
            
    }, [statusLoadData])

    useEffect(() => {

    }, [dayLeftExpire])

    useEffect(() => {
        setTextPremiumExpire('Valid until ' + premiumExpire)
    }, [premiumExpire])


    const handleTopupListResponse = (response) => {
        
        setData(response.result.topup)
        let dayLeft = getDayLeft(premiumExpire)
        setDayLeftExpire(dayLeft);
        setStatusLoadData(true)
        
    }

    const closeModal = () => {
        dispatch(premiumModalSetShow(false))
    }

    return (

        <div ClassName='premium-modal-container modal-dialog-centered'>
            <Modal.Body>
           
            {
                 (statusLoadData == true) ?
                <div className='content'>
                <div className='modal-box-close-button' onClick={closeModal}>
                    <img src={Icon} alt=''/>
                 </div>
                <Container className='text-center'>
                
                    <Row className='premium-modal-flixer-logo'>
                        <Col>
                            <img src={FlixerLogo} alt=''/>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-center mb-4'>
                        <Row className='premium-modal-information'>
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon1} alt='' />รับชมเนื้อหาใหม่ก่อนใคร
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon2} alt='' />รับชมความละเอียดได้สูงสุดแบบ Full HD
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon3} alt='' />ไม่มีโฆษณาคั่นระหว่างรับชม
                            </Col>
                            <div className='w-100' />
                            <Col>
                                <img className='premium-modal-information-icon' src={InformationIcon4} alt='' />สามารถเลือกเสียงและซับไตเติ้ลได้
                            </Col>
                        </Row>
                    </div>


                    
                    <Row className='premium-modal-button-group'>
                
                 <Col>
                     <PremiumButton firstLine='FLIXER PREMIUM' secondLine={textPremiumExpire}  />
                 </Col>
                 <div className='w-100 mb-3' />
                 <Col >
                 <label className='text-center' onClick={() => dispatch(premiumModalSetCurrentTab('topup'))}><u >ซื้อแพคเกจเพิ่มเติม</u></label>
                 </Col>
             </Row>
               


                <Row>
                 <Col>
                     <div className='premium-modal-bottom-text'>
                         การซื้อแพคเกจมีผลหลังจากชำระเงินเสร็จ
                         ใช้ได้ตามจำนวนของแพคเกจ และยกเลิกอัตโนมัติเมื่อครบกำหนด<br/>
                         ไม่สามารถใช้งานร่วมกับการเป็นสมาชิกแบบชำระเงินรายเดือนได้
                     </div>
                 </Col>
             </Row>
               
                </Container>
                </div>

                :

                '' 
                }

            </Modal.Body>
        </div>
        
    )
}

const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpireDateTopupForm)

