import React, { useCallback, useState } from 'react'
import iconHomeStreaming from '../../images/navbar/video_icon.png'
import iconHomeEbook from '../../images/navbar/ebook_icon.png'
import { useHistory } from 'react-router'
import {useSelector} from 'react-redux'
import { useResizeDetector} from 'react-resize-detector/build/withPolyfill';

const NavButtonHomeAll = () => {

    const [type, setType] = useState("list")

    const onResize = useCallback(( width, height ) => {

        const clientWith = document.documentElement.clientWidth

        if(clientWith <= 1024) {
            setType("hamburger")
        } else {
            setType("list")
        }
        
    }, []);

    const { ref } = useResizeDetector({ onResize });

    const goToHomeStreaming = () => {
        window.location.href = "/home-streaming";
        window.open('/home-streaming', '');
    }

    const goToShopEbook = () => {;
        window.open('https://shopdev.flixerapp.com', '_blank');
    }

    return (
        <div className='' ref={ref}>
        { ((type === "list") ) ?
            <div className='icon-nav-home-all' style={{marginTop: '10px'}}>
                <img className='nav-bar-flixer-logo' src={iconHomeStreaming} alt=''  style={{width: '90px', marginLeft: '20px', cursor: 'pointer'}} onClick={goToHomeStreaming}/>
                <img className='nav-bar-flixer-logo' src={iconHomeEbook} alt=''  style={{width: '90px', marginLeft: '20px', cursor: 'pointer'}} onClick={goToShopEbook}/>
            </div>
            : 
            <div className='icon-nav-home-all' style={{marginTop: '0px', float: 'left'}}>
                <img className='nav-bar-flixer-logo' src={iconHomeStreaming} alt=''  style={{width: '45px', cursor: 'pointer'}} onClick={goToHomeStreaming}/>
                <img className='nav-bar-flixer-logo' src={iconHomeEbook} alt=''  style={{width: '50px', marginLeft: '10px', marginRight: '0px', cursor: 'pointer'}} onClick={goToShopEbook}/>
            </div>
        }
        </div>
    )
}

export default NavButtonHomeAll
