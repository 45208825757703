import React from 'react'
import Logo from '../../images/redeem/finish-page-logo.png'

const FinishPage = ({message, periodInDay}) => {
        
    const handleOnClick = () => {
        window.location.href = window.flixer.homeUrl + '/'
    }

    return (
        <div>
            <div className='text-center'>
                <img className='mb-3' width='120px' src={Logo} alt='' />
                <div className='mb-5'>
                    <h4 >
                        คุณสมัครแพกเกจ VIP Premium สำเร็จ<br/>
                        {
                            // ดักไว้กรณีที่คำนวณ Period แล้วไม่ได้ค่าออกมา จะไม่แสดงผลอายุการใช้งาน
                            periodInDay !== "" ? "อายุการใช้งาน "+periodInDay+" วัน" : ""
                        }
                    </h4>
                    <div className='finish-message'>{message}</div>
                </div>
                <button className='btn button-redeem-finish' onClick={handleOnClick}>เริ่มต้นใช้งาน</button>
            </div>
        </div>
    )
}

export default FinishPage
