import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { redeemSetFinish, redeemSetFinishMessage, redeemSetFinishMessageProductPeriodInDay } from '../../actions'
import { callAISRequestOTPApi, callAISConfirmOTPApi, callAISPackageListApi, receiptValidator } from '../../api'
import { getSubscriptionProductPeriodInDayFromReceiptValidatorResponse, hideLoading, showLoading } from '../../helper/Helper'
import LogoAIS from '../../images/redeem/logo-ais.png'

const AisRedeemForm = () => {

    const dispatch = useDispatch()

    const [mobileNumber, setMobileNumber] = useState('')
    const [otp, setOTP] = useState('')
    // step === 1 คือ กรอกหมายเลขโทรศัพท์เพื่อขอOTP  
    // step === 2 คือ ยืนยันOTP
    const [step, setStep] = useState(1)

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if( step === 1 ){
            requestOTP()
        }else if( step === 2 ){
            confirmOTP()
        }

    }
    
    const handleMobileNumberChange = (event) => {

        let mobileNumber = event.target.value;
        mobileNumber = mobileNumber.replace(/[^0-9]/g,"")
        mobileNumber = mobileNumber.substr(0,10);
        setMobileNumber(mobileNumber)

        if ( mobileNumber.length < 10) {
            event.target.setCustomValidity("กรุณากรอกหมายเลขมือถือ10หลัก"); 
        } else {
            event.target.setCustomValidity("");
        }

    };

    const handleOTPChange = (event) => {
        event.target.setCustomValidity("");
        let otp = event.target.value;
        otp = otp.replace(/[^0-9]/g,"")
        setOTP(otp)
    }


    const requestOTP = async () => {
        showLoading()
        let response = await callAISRequestOTPApi(mobileNumber)

        if( response.result === 'success' ) {
            setStep(2)
        }else{
            alert(response.message)
        }
        hideLoading()
    }

    const confirmOTP = async () => {
        showLoading()
        let response = await callAISConfirmOTPApi(mobileNumber, otp)
        if( response.result === 'success' ) {
            getPackageList()
        }else{
            alert(response.message)
            hideLoading()
        }
    }

    const getPackageList = async () => {
        let response = await callAISPackageListApi(mobileNumber)

        if( response.error ){
            alert(response.message)
		}else{
            // เรียก receipt validator เพื่อให้ค่าที่ redeem update กับ user
            let receiptValidatorResponse = await receiptValidator()
            let productPeriodInDay = getSubscriptionProductPeriodInDayFromReceiptValidatorResponse(receiptValidatorResponse)

            dispatch(redeemSetFinishMessageProductPeriodInDay(productPeriodInDay))
            dispatch(redeemSetFinishMessage(response.premium.message))
            dispatch(redeemSetFinish(true))
		}
        hideLoading()
    }

    const resendOTP = () => {
        setOTP('')
        requestOTP()
    }


    return (
        <form className='ais-redeem-form' onSubmit={handleOnSubmit}>
            <Row>
                <Col className='mb-1'>
                    <h5><b>ทางเลือก 2: หมายเลขโทรศัพท์ <img className='ais-redeem-form-ais-icon' src={LogoAIS} alt=''/></b></h5>
                </Col>
                <div className='w-100' />
                <Col className='font-size-95 text-secondary-light-color mb-1'>
                    กรุณาระบุหมายเลขโทรศัพท์
                </Col>
                <div className='w-100' />
                <Col className='mb-3'>
                    <input 
                        id='mobile-number'
                        className='form-control'
                        name='mobilenumber'
                        type="text"
                        placeholder="หมายเลขโทรศัพท์"
                        minLength='10'
                        maxLength='10'
                        value={mobileNumber}
                        onInvalid={(e) => e.target.setCustomValidity("กรุณากรอกหมายเลขมือถือ10หลัก")}
                        onChange={handleMobileNumberChange}
                        required 
                        readOnly={step===2}
                    />
                    <div hidden={step === 1}>
                        <input 
                            id='test'
                            className='form-control mt-3 mb-1'
                            type='text'
                            placeholder='หมายเลขOTP'
                            value={otp}
                            onInvalid={(e) => e.target.setCustomValidity("กรุณากรอกหมายเลขOTP")}
                            onChange={handleOTPChange}
                            required
                            disabled={step === 1}
                        />
                        <label className='font-size-85 text-secondary-light-color'>
                            (รหัสผ่านมีอายุการใช้งาน 5 นาที) ไม่ได้รับรหัส OTP ใช่ไหม? <span className='ais-redeem-form-resend-otp' onClick={resendOTP}>ขอรหัส OTPอีกครั้ง</span>
                        </label>
                    </div>
                </Col>
                <div className='w-100' />
                <Col>
                    { 
                        step === 2 
                        ? 
                        <button className='btn button-submit-ais-redeem'>ยืนยันOTP</button>
                        :
                        <button className='btn button-submit-ais-redeem'>ขอรหัส OTP</button>
                    }
                </Col>

                <div className='w-100'/>
                <Col className='mt-4'>
                    <b>วิธีการรับสิทธิ์ FLIXER PREMIUM ฟรี 30 วัน</b><br/>
                    1. ใช้หมายเลข AIS กดสมัครผ่าน *257*1# โทรออก หรือสมัครผ่านเว็บไซต์ <a href="https://m.ais.co.th/FlixerMonthly" rel="noreferrer" target="_blank">คลิก</a><br/>
                    2. เมื่อสมัครสำเร็จ จะได้รับ SMS ยืนยัน ระบุวันที่สมัครสำเร็จ
                </Col>

            </Row>
        </form>
        
    )
}

export default AisRedeemForm
