const initialState = {
    hidden: true,
    currentTab: '',
    userData: null
}

const loginModal = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_MODAL_SET_HIDDEN':
            return { ...state, hidden: action.hidden}
        case 'LOGIN_MODAL_SET_CURRENT_TAB':
            return { ...state, currentTab: action.currentTab }
        case 'LOGIN_MODAL_SET_USER_DATA':
            return { ...state, userData: action.userData }
        default:
            return state
    }
}
  
export default loginModal
