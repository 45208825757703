import React, { useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {FaFacebookSquare} from 'react-icons/fa'
import { fbLogin, sendVerifyEmail } from '../../api'
import { alertErrorByCode, firebaseLogEvent, hideLoading, showLoading } from '../../helper/Helper'
import { loginModalSetCurrentTab, loginModalSetHidden, loginModalSetUserData } from '../../actions'
import { useDispatch } from 'react-redux'
import FormEmailWithFacebook from '../../containers/login/FormEmailWithFacebook'
import { Button, Modal } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'


const FacebookLoginButton = () => {

    const dispatch = useDispatch()
    const [dataNotEmailFB, setDataNotEmailFB] = useState(null);
    const [data, setData] = useState();

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")
    

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose();
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse);

    }

    

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        alert(response.message.message)

    }

    const handleOnCloseButtonClick = () => {
        handleClose()
    }

    const responseFacebook = (response) => {

        // มี id มาแสดงว่า success
        if( response && typeof response.id ){

            if( typeof response.email !== 'undefined' ){

                let fbData = {
                    fbid: response.id,
                    fbemail: response.email,
                    fbname: response.name,
                    fbtoken: response.accessToken,
                    fbgender: response.gender ? response.gender : ''
                }
                showLoading()
                fbLogin(fbData).then(handleFacebookLoginResponse)
    
            }else{
                // // กรณีที่ลูกค้าไม่ยินยอม email 
                // alertErrorByCode("0001")

                let fbData = {
                    fbid: response.id,
                    fbemail: '',
                    fbname: response.name,
                    fbtoken: response.accessToken,
                    fbgender: response.gender ? response.gender : ''
                }
                dispatch(loginModalSetUserData(fbData));
                // setDataNotEmailFB(fbData);
                fbLogin(fbData).then(handleFacebookLoginResponse)
                // dispatch(loginModalSetUserData(fbData));
                // dispatch(loginModalSetCurrentTab('email-facebook'));

                // if () {
                //     //กรณีไม่ได้ email จาก facebook จะให้ลูกค้ากรอก email เพิ่ม
                //     dispatch(loginModalSetCurrentTab('email-facebook'));
                // } else {
                // // // กรณีที่ลูกค้าไม่ยินยอม email 
                // // alertErrorByCode("0001")
                // }
            }

        }


    }

    const handleFacebookLoginResponse = async (response) => {

        hideLoading()

        if( response.error === false ){
            firebaseLogEvent('login', { method: "Facebook"})
            window.location.reload()
        }else{
            if(response.error_code === 112) {
                // dispatch(loginModalSetUserData(data));
                dispatch(loginModalSetCurrentTab('email-facebook'));
            }else if(response.error_code === 122) {
                setModalTextTitleSendEmail(response.message.title)
                setModalTextDetailSendEmail(response.message.message)
                setModalButton1SendEmail('OK')
                setModalButton2SendEmail(response.message.button)
                setVerifyEmailToken(response.result.verifyEmailToken)
                setShowModal(true)
            } else {
                alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
            }
        }

    }

    useEffect(() => {
        if (dataNotEmailFB != null) {
            setData(dataNotEmailFB);
        }
    }, [dataNotEmailFB])


    return (
        <>
        <FacebookLogin
            appId="1963912973622509"
            fields="id, first_name, last_name, email, name"
            scope="email"
            callback={responseFacebook}
            authType="rerequest"
            version='9.0'
            disableMobileRedirect={true}
            render={renderProps => (
                <button onClick={renderProps.onClick} className='signin-with-facebook-button'><FaFacebookSquare fill='white'/>Sign in with Facebook</button>
            )}
        />

            <Modal className='' show={showModal} onHide={handleClose}>
                <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
                    <Modal.Header  style={{color: 'white', borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <ModalCloseButton onClick={handleClose}/> */}
                        <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>

                        {modalTextDetailSendEmail}
                        
                    </Modal.Body>
                    <Modal.Footer style={{ borderColor: 'rgba(255,255,255, 0.3)'}}>
                    {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
                        <Button className='btn btn-outline' style={{color: 'var(--color-orange)', backgroundColor: 'RGB(63,63,63)', borderColor: 'var(--color-orange)', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
                        {modalButton2SendEmail}
                        </Button>
                        <Button variant="primary" style={{backgroundColor: 'var(--color-orange)', borderColor: 'var(--color-orange)', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
                        {modalButton1SendEmail}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    )
}

export default FacebookLoginButton
