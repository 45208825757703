import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useHistory } from 'react-router';
import {goToCategoryContent, goToDownloadApp, goToFlixerPlusPage, goToHomePage, goToRedeemPage} from '../../helper/Helper'
import { slide as Menu } from 'react-burger-menu'
import { loginModalSetHidden, siteSetCurrentPage } from '../../actions';
import { isUserLoggedIn } from '../../helper/ReduxStoreHelper';
import { Modal } from 'react-bootstrap';
import iconHomeEbook from '../../images/navbar/ebook_platformIcon.png'
import iconHomeStreaming from '../../images/navbar/video_platformIcon.png'
import iconGift from '../../images/navbar/gift_icon.png'

const MobileSlideMenu = ({categoryList}) => {

    const [menuOpen, setMenuOpen] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const goToHomeStreaming = () => {
        // goToCategoryContent(history, "anime")
        // window.location.href = "/redeem";
        handleClose();
        if( isUserLoggedIn()){
            goToRedeemPage(history)
        } else {
            dispatch(loginModalSetHidden(false))
        }
    }

    const handleCategoryOnClick = (slug) => {
        closeMenu()
        goToCategoryContent( history, slug )
    }

    const handleStateChange =  (state) => {
        setMenuOpen(state.isOpen) 
    }
    
    const closeMenu = () => {
        setMenuOpen(false) 
    }

    const handleHomeOnClick = () => {
        closeMenu() 
        dispatch(siteSetCurrentPage('home'))
        // ต้อง close menu และใส่ active ก่อนเปลี่ยนหน้า เนื่องจากถ้าทำพร้อมกันแล้ว menu จะปิดช้าตามความหน่วงของการโหลดหน้านั้นๆ
        setTimeout(() => {
            goToHomePage(history)
        }, 50)
        
    }

    const handleFlixerPlusOnClick = () => {
        closeMenu() 
        dispatch(siteSetCurrentPage('flixer-plus'))
        // ต้อง close menu และใส่ active ก่อนเปลี่ยนหน้า เนื่องจากถ้าทำพร้อมกันแล้ว menu จะปิดช้าตามความหน่วงของการโหลดหน้านั้นๆ
        setTimeout(() => {
            goToFlixerPlusPage(history)
        }, 50)
    }

    const handleRedeemOnClick = () => {
        closeMenu() 
        setTimeout(() => {
            setShowModal(true)
            // if( isUserLoggedIn()){
            //     goToRedeemPage(history)
            // }else{
            //     dispatch(loginModalSetHidden(false))
            // }
        }, 50)
    }

    const handleDownloadOnClick = () => {
        closeMenu() 
        // ต้อง close menu และใส่ active ก่อนเปลี่ยนหน้า เนื่องจากถ้าทำพร้อมกันแล้ว menu จะปิดช้าตามความหน่วงของการโหลดหน้านั้นๆ
        setTimeout(() => {
            goToDownloadApp();
        }, 50)
    }

    
    const siteCurrentPage = useSelector(state => state.site.currentPage)

    return (
        <>
        <Menu className='slide-menu-container'
        isOpen={menuOpen}
        onStateChange={(state) => handleStateChange(state)} >
            {(window.location.pathname !== '/') ? 
            <>
            <div className={"menu-item menu-item-home" + (siteCurrentPage === 'home' ? ' active' : '')} onClick={handleHomeOnClick}>Home</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'children' ? ' active' : '')} onClick={() => handleCategoryOnClick('children')}>Kids & Family</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'idol' ? ' active' : '')} onClick={() => handleCategoryOnClick('idol')}>ไอดอล</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'ultraman' ? ' active' : '')} onClick={() => handleCategoryOnClick('ultraman')}>อุลตร้าแมน</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'masked rider' ? ' active' : '')} onClick={() => handleCategoryOnClick('masked rider')}>มาสค์ไรเดอร์</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'movie' ? ' active' : '')} onClick={() => handleCategoryOnClick('movie')}>ภาพยนตร์</div>
            <div className={"menu-item menu-item-category-popular" + (siteCurrentPage === 'flixer-plus' ? ' active' : '')} onClick={handleFlixerPlusOnClick}>Flixer+</div>
            {/* ----- ปุ่ม Redeem จะแสดงผลเฉพาะ user ที่ไม่ login ----- */}
            { !isUserLoggedIn() ? <div className={"menu-item menu-item-redeem" + (siteCurrentPage === 'redeem' ? ' active' : '')} onClick={handleRedeemOnClick}>Redeem</div> : '' } 
            </>
            : ''}

            {(window.location.pathname === '/') ? <><div className={"menu-item menu-item-home" + (siteCurrentPage === 'home' ? ' active' : '')} onClick={handleHomeOnClick}>Home</div><hr></hr></> : '' }
            <div className="menu-item menu-item-flixer-plus" onClick={handleDownloadOnClick}>Download APP</div>
            <hr></hr>
            {(window.location.pathname !== '/') ? 
            <>
            <div className="menu-item menu-item-section-header">Categories</div>
            {
                categoryList.map( (category, index) => {
                    return <div key={index} className="menu-item" onClick={() => handleCategoryOnClick(category.slug)}>{category.name}</div>
                })
            }
            </> : ''
            }
        </Menu>

        <Modal className='' show={showModal} onHide={handleClose} style={{flexDirection: 'unset !important'}} centered>
            <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none', borderRadius: '9px', flexDirection: 'unset'}}>
                <Modal.Body style={{color: 'white', textAlign: 'center', paddingTop: '30px', paddingBottom: '50px'}}>
                <p><img className='nav-bar-flixer-logo' src={iconGift} alt=''  style={{width: '15px', marginLeft: '20px', cursor: 'pointer', paddingBottom: '8px'}} />  โปรดเลือก Platform ที่คุณต้องการ Redeem</p>
                <div style={{display: 'flex', textAlign: 'center', margin: 'auto', width: 'max-content', marginTop: '30px'}}>
                    <div style={{textAlign: 'center' }}>
                        <p><img className='nav-bar-flixer-logo' src={iconHomeStreaming} alt=''  style={{width: '60px', cursor: 'pointer'}} onClick={goToHomeStreaming}/></p>
                        <label>Video</label>
                    </div>
                    <div style={{textAlign: 'center' ,marginLeft: '50px'}}>
                        <p><img className='nav-bar-flixer-logo' src={iconHomeEbook} alt=''  style={{width: '60px', cursor: 'pointer'}} /></p>
                        <label>Ebook</label>
                    </div>
                </div>
                    
                </Modal.Body>
            </div>
            </Modal>
        </>
    )
}

export default MobileSlideMenu
