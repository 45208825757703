import React from 'react'
import {useHistory} from 'react-router-dom'
import {goToCategoryContent} from '../../helper/Helper'
import {useSelector} from 'react-redux'

const CategoriesButton = ({categoryList}) => {

    const siteCurrentPage = useSelector(state => state.site.currentPage)

    const history = useHistory();

    const handleOnClick = (slug) => {
        goToCategoryContent( history, slug )
    }

    return (
        <div className={ siteCurrentPage === 'category' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'}>
            <div className='nav-bar-text-menu-button-name'>
                Categories
            </div>
            <div className='nav-bar-text-menu-button-list text-start'>
                {
                    categoryList.map( (category, index) => {
                        return <div key={index} onClick={() => handleOnClick(category.slug)}>{category.name}</div>
                    })
                }
            </div>
        </div>
    )
}

export default CategoriesButton
