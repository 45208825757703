import React from 'react'
import {useHistory} from 'react-router-dom'
import { goToFlixerPlusPage } from '../../helper/Helper'
import {useSelector} from 'react-redux'

const FlixerPlusButton = () => {

    const siteCurrentPage = useSelector(state => state.site.currentPage)

    const history = useHistory()

    return (
        <div className={ siteCurrentPage === 'flixer-plus' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'} onClick={() => goToFlixerPlusPage(history)}>
            <div className='nav-bar-text-menu-button-name'>
                Flixer+
            </div>
        </div>
    )
}

export default FlixerPlusButton
