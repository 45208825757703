import React from 'react'
import { Row, Col } from 'react-bootstrap'

const FirstColumn = () => {

    const getCurrentYear = () => {
        let date = new Date()
        return date.getFullYear()
    }

    return (
        <Row>
            <Col className='mb-3'>
                <a target='_blank' href='/term-of-use'>Terms of Use</a> 
                <a target='_blank' href='/privacy-policy'>Privacy Policy</a>
                {/* <a href='#'>FAQ</a> */}
                <a href='mailto:support@flixerapp.com'>Advertisement</a>
            </Col>
            <div className='w-100'></div>
            <Col>
                Copyright &copy; 2017 - {getCurrentYear()} Flixer Co., Ltd.
            </Col>
        </Row>
    )
}

export default FirstColumn
