import React from 'react'

const AboutFlixerPlus = () => {
    return (
        <div className='about-flixer-plus-container'>
            <div className='about-flixer-plus-title'>เกี่ยวกับ Flixer+</div>
            <div className='about-flixer-plus-information'>
                {window.flixer.aboutFlixerPlusDescription}
            </div>
        </div>
    )
}

export default AboutFlixerPlus