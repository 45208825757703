import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { redeemSetFinish, redeemSetFinishMessage, redeemSetFinishMessageProductPeriodInDay } from '../../actions'
import { callApiRedeem, receiptValidator } from '../../api'
import { getSubscriptionProductPeriodInDayFromReceiptValidatorResponse, hideLoading, showLoading } from '../../helper/Helper'


const RedeemCodeForm = () => {

    const dispatch = useDispatch()
    
    const [code, setCode] = useState("")

    const handleCodeOnChange = (e) => {
        e.target.setCustomValidity("");
        setCode(e.target.value)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        showLoading()
        let response = await callApiRedeem(code)
        if( response.error === false ){
            // เรียก receipt validator เพื่อให้ค่าที่ redeem update กับ user
            let receiptValidatorResponse = await receiptValidator()
            let productPeriodInDay = getSubscriptionProductPeriodInDayFromReceiptValidatorResponse(receiptValidatorResponse)

            dispatch(redeemSetFinishMessageProductPeriodInDay(productPeriodInDay))
            dispatch(redeemSetFinishMessage(''))
            dispatch(redeemSetFinish(true))
        }else{
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
        }
        hideLoading()
    }



    return (
        <form onSubmit={handleOnSubmit}>
            <Row>
                <Col className='mb-1'>
                    <h5><b>ทางเลือก 1: รหัสแลกสิทธิ์</b></h5>
                </Col>
                <div className='w-100' />
                <Col className='font-size-95 text-secondary-light-color mb-1'>
                    กรุณากรอกรหัสแลกสิทธิ์
                </Col>
                <div className='w-100' />
                <Col className='mb-3'>
                    <input type="text"
                        className="form-control"
                        name="redeem_code"
                        value={code}
                        onChange={handleCodeOnChange} 
                        onInvalid={(e) => e.target.setCustomValidity("กรุณากรอกรหัสแลกสิทธิ์")}
                        placeholder="รหัสแลกสิทธิ์" 
                        required
                    />
                </Col>
                <div className='w-100' />
                <Col>
                    <button className='btn button-redeem-code'>แลกสิทธิ์</button>
                </Col>
            </Row>
        </form>
    )
}

export default RedeemCodeForm
