import React, { useEffect, useState } from 'react'
import { callApiRentalList, getVerifyAccountStatus, removeAccount, sendVerifyAccountEmail } from '../../api'
import AccountModalHeader from './AccountModalHeader'
import RentalContentList from './RentalContentList'
import { useDispatch, useSelector } from 'react-redux'
import { accountModalSetCurrentTab } from '../../actions'
import CorrectIcon from '../../images/account-modal/correct.png'
import { useTranslation } from 'react-i18next'
import { hideLoading, showLoading } from '../../helper/Helper'

const EmailProfile = (emailProfile) => {

    const dispatch = useDispatch()

    const { t , i18n} = useTranslation('trans');

    const [statusVerify, setStatusVerify] = useState(false)
    const [textVerify, setTextVerify] = useState('')
    const [textResendVerify, setTextResendVerify] = useState('')
    const [emailLogin, setEmailLogin] = useState('')

    const userIsPremium = useSelector(state => state.user.isPremium)

    const userProfile = useSelector(state => state.user.profile)

    useEffect( () => {
        setEmailLogin(emailProfile)
        showLoading()
        getVerifyAccountStatus().then(handleCheckResponse)
        
    }, [])



    const handleCheckResponse = (response) => {
        // window.location.reload()
        // alert(response);

        if (response.result.verified === '1') {
            setStatusVerify(true);
            setTextVerify(t('Verified'))
        } else {
            setStatusVerify(false);
            setTextVerify(t('NotVerified'))
            setTextResendVerify(t('ResendVerificationEmail'))
        }

        hideLoading();

        // setStatusVerify(true);


    }

    const handlesendVerifyEmailAccount = () => {

        sendVerifyAccountEmail().then(handleSendVerify);
   
    }

    const handleSendVerify = (response) => {

        if (response.result.mailsent === true) {
            alert(response.message.message);
        } else {
           
        }
    }

    return (
        <div>
            <AccountModalHeader title={t('Email')} haveBackButton={true} backButtonFunction={() => dispatch(accountModalSetCurrentTab(''))}/>
            <div className='account-modal-list' style={{color : "white", marginTop: "30px",cursor: "pointer" }} >{userProfile.email}</div>
            { (statusVerify === true) ? 
            <div style={{ marginTop: '5px' }}><font color="#2ECC71" style={{marginLeft: "15px", fontSize: '15px', fontWeight: 'bold'}}><img src={CorrectIcon} width={'15px'} style={{marginRight: "5px", marginBottom: '5px'}}/> {textVerify}</font></div>
        : 
            <div className='' style={{ marginTop: '5px' }}>
                <div><font color="red" style={{marginLeft: "15px", fontSize: '15px', fontWeight: 'bold'}}>{textVerify}</font></div>
                <div><font color="gray" style={{cursor: 'pointer',marginLeft: "15px", fontSize: '14px', fontWeight: 'bold'}} onClick={() => handlesendVerifyEmailAccount()}><u>{textResendVerify}</u></font></div>
            </div>
        }
            
            
        </div>
    )
}

export default EmailProfile
