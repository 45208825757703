import React from 'react'
import MediaLink from '../../containers/global/MediaLink'

const MediaContent = ({ mediaId, thumbnailUrl, name, type }) => {

    return (
        <MediaLink mediaId={mediaId} type={"mediaContent"} typeContent={type}>
            <div className='media-content cursor-pointer'>
                <div>
                    <img src={thumbnailUrl} alt='' loading="lazy"/>
                </div>
                <div className='media-content-name' style={{ color: (type === 'ebook') ? '#000' : ''}}>
                    {name}
                </div>
            </div>
        </MediaLink>
    )
}

export default MediaContent;
  
