import React from 'react'
import { useHistory } from 'react-router-dom'
import {goToSeeAllPage} from '../../helper/Helper'

const SeeAllButton = ({shelfId, type}) => {

    let history = useHistory();

    const handleOnClick = () => {
        if (type === 'ebook') {
            window.open('https://shopdev.flixerapp.com', '_blank');
        } else {
            goToSeeAllPage(history, shelfId)
        }
        
    }

    return (
        <div className='see-all-button cursor-pointer position-relative float-end' onClick={handleOnClick}>
            See All
        </div>
    )
}

export default SeeAllButton
