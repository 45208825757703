import React from 'react'
import { useDispatch } from 'react-redux'
import { premiumModalSetShow } from '../../actions'
import MenuButton from './MenuButton'
import PremiumIcon from '../../images/navbar/premium.png'

const PremiumButtonMobile = () => {

    const dispatch = useDispatch()

    return (
        <div className='nav-bar-menu-button nav-bar-premium-menu-button' onClick={() => dispatch(premiumModalSetShow(true))}>
            <MenuButton 
                className='premium-menu-button'
                image={PremiumIcon} 
                name={"Premium"} />
        </div>
    )
}

export default PremiumButtonMobile
