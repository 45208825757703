const initialState = {
    currentPage: '', 
    beforeTitleVideo: '',
    isAvailable: {
        status: true,
        message: ''
    },
    isUserAlreadyInteractWithMedia: false,
    forcePlayVideo: false,
    isClickFromSlideShow: false,
    
}

const media = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_SET_CURRENT_PAGE':
            return { ...state, currentPage: action.currentPage }
        case 'SITE_SET_IS_AVAILABLE_STATUS':
            return {...state, isAvailable: {...state.isAvailable, status: action.isAvailableStatus }}
        case 'SITE_SET_IS_AVAILABLE_MESSAGE':
            return {...state, isAvailable: {...state.isAvailable, message: action.isAvailableMessage }}
        case 'SITE_SET_FORCE_PLAY_VIDEO':
            return { ...state, forcePlayVideo: action.forcePlayVideo}
        case 'SITE_SET_IS_USER_ALREADY_INTEREACT_WITH_MEDIA':
            return { ...state, isUserAlreadyInteractWithMedia: action.isUserAlreadyInteractWithMedia}
        case 'SITE_SET_CLICK_FROM_SLIDE_SHOW':
            return { ...state, isClickFromSlideShow: action.isClickFromSlideShow}
        case 'SITE_SET_BEFORE_TITLE_VIDEO':
            return { ...state, beforeTitleVideo: action.beforeTitleVideo }
        default:
            return state
    }
}
  
export default media
