import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {FaYoutube, FaFacebook, } from 'react-icons/fa'
import {FiMail} from 'react-icons/fi'

const SecondColumn = () => {
    return (
        <Row>
            <Col className='mb-2'>
                Connect with us
            </Col>
            <div className='w-100'></div>
            <Col>
                <a className='icon facebook-icon' rel="noreferrer" target='_blank' href='https://www.facebook.com/FLIXERAPP'><FaFacebook /></a>
                <a className='icon youtube-icon' rel="noreferrer" target='_blank' href='https://www.youtube.com/channel/UCOix5pqVVniUXxTnymKsbnQ'><FaYoutube /></a>
                <a className='icon email-icon' href='mailto:support@flixerapp.com'><FiMail /></a>
            </Col>

        </Row>
    )
}

export default SecondColumn
