import React from 'react'
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import SampleAds750x100 from '../../images/ads/ads750x100.jpeg'
import SampleAds240x400 from '../../images/ads/ads240x400.jpeg'
import SampleAds300x250 from '../../images/ads/ads300x250.jpeg'

const Ads = ({adUnitId, sampleImage, width, height}) => {

    if( width === 750 ){
        sampleImage = SampleAds750x100
    }else if( width === 240 ){
        sampleImage = SampleAds240x400
    }else if( width === 300 ){
        sampleImage = SampleAds300x250
    }

    const style ={
        background: 'url("'+sampleImage+'")', 
        width: width+'px' , 
        height: height+'px',
        margin: 'auto',
        cursor: 'pointer'
    }

    const sizes = [
        [width, height]
    ]

    // กรณีที่ไม่มี Ads หรือเรียก Ads แล้ว Error click แล้วจะเข้า link ด้านล่าง
    const handleOnClick = () => {
        window.open('https://bit.ly/DL-OishiClub');
    }

    return (
        <div className='ads-container' >
            <DFPSlotsProvider dfpNetworkId=''>
                <div className="desktop-ads" style={style} onClick={handleOnClick}>
                    {adUnitId ? <AdSlot sizes={sizes} adUnit={adUnitId} /> : ""}
                </div>
            </DFPSlotsProvider>
        </div>
    )
}

export default Ads
