import React from 'react'
import {useSelector} from 'react-redux'
import ContinueWatchingContent from './ContinueWatchingContent'
import { Swiper, SwiperSlide } from 'swiper/react';
import DragScrollContainer from '../../components/helper/DragScrollContainer'
import useClientWidth from '../../hooks/useScreenWidth';
import { checkIsMobileWidth } from '../../helper/Helper';

const ContinueWatchingList = () => {

    const clientWidth = useClientWidth()

    const list = useSelector(state => state.home.continueWatchingList)

    return (
        <div className='continue-watching-container'>
            {
                list.length > 0 
                && 
                <React.Fragment>
                    <div className='continue-watching-title mb-1'>
                        ดูต่อเลยมั๊ย
                    </div>
                    {
                            checkIsMobileWidth(clientWidth)
                        ?
                            <DragScrollContainer className='continue-watching-list full-width-container-with-start-spacing'>
                                {  
                                    list.map( (details, index) => {
                                        return <ContinueWatchingContent key={index} details={details}/>
                                    })
                                }
                            </DragScrollContainer>
                        :
                            <Swiper 
                                slidesPerView={'auto'} 
                                slidesPerGroup={1}
                                freeMode={true} 
                                navigation={true}
                                allowTouchMove={false}
                                autoHeight={true}
                                speed={500}
                                className='horizontal-list continue-watching-list full-width-container-with-start-spacing'
                            >
                                {
                                    list.map( (details, index) => {
                                        return  <SwiperSlide key={index}><ContinueWatchingContent key={index} details={details}/></SwiperSlide>
                                    })
                                }
                            </Swiper>
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default ContinueWatchingList
