import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import {Modal, Container, Row, Col} from 'react-bootstrap'
import { accountModalSetCurrentTab, accountModalSetShow, premiumModalSetCurrentTab, premiumModalSetShow } from '../../actions'
import { changePassword, logout } from '../../api'
import ImageProfile from './ImageProfile'
import { firebaseLogEvent, locationToHomePage, hideLoading, showLoading, goToRedeemPage, validateEmail } from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import PremiumIcon from './../../images/navbar/premium.png'
import FlixerPlusAccount from './FlixerPlusAccount'
import AccountModalHeader from './AccountModalHeader'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import { useHistory } from 'react-router'
import ManageAccount from './ManageAccount'
import EmailProfile from './EmailProfile'


const AccountModal = ({show, currentTab, userProfile, dispatch}) => {

    const platformPremium = useSelector(state => state.user.premiumPlatform)
    const premiumExpire = useSelector(state => state.user.premiumExpire)
    const userEmail = useSelector(state => state.user.profile.email)

    const history = useHistory()

    const closeModal = () => {
        dispatch(accountModalSetShow(false))
    }

    const handleChangePassword = () => {
        showLoading()
        changePassword().then(handleChangePasswordResponse)
    }
    const handleChangePasswordResponse = (response) => {
        hideLoading()
        alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
    }


    const handleSignOut = () => {
        let confirm = window.confirm("Are you sure you want to sign out?");
        if (confirm) {
            showLoading()
            logout().then(handleLogoutResponse)
        }
    }

    const handleLogoutResponse = (response) => {
        hideLoading()
        if( response.error === false ){
            locationToHomePage()
        }else{
            alert(response.message)
        }

    }

    useEffect(() => {
        if(show){
            firebaseLogEvent('page_view',{
                page_title: 'MyProfile Modal',
                page_location: window.location.origin + '/modal/my-profile',
                page_path: '/modal/my-profile'
            })
        }
    }, [show])

    const clickButtonSubscription = (productId, price, topupDays) => {

        dispatch(premiumModalSetShow(true))

        if(isUserLoggedIn()) {
            if( platformPremium === "flixer-topup") {
                if(premiumExpire != null) {
                    dispatch(premiumModalSetCurrentTab('topupExpire'))
                } else {
                    dispatch(premiumModalSetCurrentTab('topup'))
                }
                
            } else {
                dispatch(premiumModalSetCurrentTab(''))
            }
            
        } else {
            dispatch(premiumModalSetCurrentTab(''))
        }
    }


    return (
        <Modal
            show={show}
            onHide={closeModal}
            dialogClassName={isUserPremium() ? 'account-modal-container premium-account modal-dialog-centered' : 'account-modal-container modal-dialog-centered'}
        >
            <Modal.Body>
                <ModalCloseButton onClick={closeModal}/>
                {
                    currentTab === '' &&
                    <Container>
                        <Row className='mb-5'>
                            <AccountModalHeader title={'My Profile'}/>
                        </Row>

                        <Row className='mt-5 mb-5'>
                            <ImageProfile imageProfile={userProfile.profileImageUrl} />
                            <div className='w-100'/>
                            <Col className='account-modal-premium-label text-center mt-3'>
                                <img src={PremiumIcon} alt='' />
                                <span>Premium</span>
                            </Col>
                        </Row>

                        <Row className='account-modal-list-container'>

                        { ( !validateEmail(userEmail) ) ?
                            <Col className='account-modal-list account-modal-list-email' >
                                <Row>
                                    <Col>
                                        Email
                                    </Col>
                                    <Col className='text-end account-modal-text-secondary-color'>
                                        Not set
                                    </Col>
                                </Row>
                            </Col>
                            : 
                            <Col className='account-modal-list account-modal-list-email' onClick={() => dispatch(accountModalSetCurrentTab('email'))}>
                            <Row>
                                <Col>
                                    Email
                                </Col>
                                <Col className='text-end account-modal-text-secondary-color'>
                                    {userProfile.email}
                                </Col>
                            </Row>
                        </Col>
                    }

                            { (validateEmail(userEmail)) ? 
                            <>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-change-password cursor-pointer' onClick={handleChangePassword}>
                                Change password
                            </Col> 
                            </> : ''
                            }
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-subscription cursor-pointer' onClick={() => clickButtonSubscription()}>
                                Subscription
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-flixer-plus cursor-pointer' onClick={() => dispatch(accountModalSetCurrentTab('rental'))}>
                                Flixer+
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-redeem cursor-pointer' onClick={() => { closeModal(); goToRedeemPage(history); }}>
                                Redeem
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-subscription cursor-pointer' onClick={() => dispatch(accountModalSetCurrentTab('manageAccount'))}>
                                Manage Account
                            </Col>
                            <div className='w-100'></div>
                            <Col className='account-modal-list account-modal-list-signout cursor-pointer' onClick={handleSignOut}>
                                Sign Out
                            </Col>
                        </Row>
                    </Container>
                }
                {
                    currentTab === 'rental' &&
                    <FlixerPlusAccount />
                }
                {
                    currentTab === 'manageAccount' &&
                    <ManageAccount />
                }
                {
                    currentTab === 'email' &&
                    <EmailProfile emailProfile={userProfile.email} />
                }
            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = (state, ownProps) => ({
    show: state.accountModal.show,
    currentTab: state.accountModal.currentTab,
    userProfile: state.user.profile
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountModal)
