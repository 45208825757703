import React from 'react'
import { goToHomePage } from '../../helper/Helper'
import { useHistory } from 'react-router'
import {useSelector} from 'react-redux'

const HomeButton = () => {

    const siteCurrentPage = useSelector(state => state.site.currentPage)

    const history = useHistory();

    return (
        <div className={ siteCurrentPage === 'home' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'} onClick={() => goToHomePage(history)}>
             <div className='nav-bar-text-menu-button-name'>
                Featured
            </div>
        </div>
    )
}

export default HomeButton
