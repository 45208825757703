import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { goToHomePage } from '../../helper/Helper'
import { isUserPremium } from '../../helper/ReduxStoreHelper'
import FlixerLogo from '../../images/flixer-logo.png'
import FlixerPremiumLogo from '../../images/flixer-premium-logo.png'
import FlixerStreamingLogo from '../../images/flixer-streaming-logo.png'


const FlixerIcon = () => {

    const history = useHistory();

    const user = useSelector(state => state.user)

    return (
        <div className='cursor-pointer' onClick={() => goToHomePage(history)}>
            {
                    isUserPremium() 
                ?
                    <img className='nav-bar-flixer-premium-logo' src={FlixerPremiumLogo} alt='' />
                :    (window.location.pathname === '/home-streaming') ? 
                    <img className='nav-bar-flixer-logo' src={FlixerStreamingLogo} alt='' />
                    :
                    <img className='nav-bar-flixer-logo' src={FlixerLogo} alt='' />
            }
            
        </div>
    )
}

export default FlixerIcon
