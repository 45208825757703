import React, {useState} from 'react'
import { connect } from 'react-redux'
import { loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import { emailSignUp, sendVerifyEmail } from '../../api'
import TermAndPrivacy from '../../components/login/TermAndPrivacy'
import { firebaseLogEvent, hideLoading, showLoading, validateEmail, validatePassword } from '../../helper/Helper'
import { Container, Modal, Row } from 'react-bootstrap'
import ModalCloseButton from '../../components/global/ModalCloseButton'
import Button from 'react-bootstrap/Button';

const SignUpForm = ({show, dispatch}) => {

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [modalTextDetailSendEmail, setModalTextDetailSendEmail] = useState("")
    const [modalTextTitleSendEmail, setModalTextTitleSendEmail] = useState("")
    const [modalButton1SendEmail, setModalButton1SendEmail] = useState("")
    const [modalButton2SendEmail, setModalButton2SendEmail] = useState("")
    const [verifyEmailToken, setVerifyEmailToken] = useState("")

    const closePopup = () => {
        setShowModal(false);
      };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleEmailSignUpResponse = async (response) => {

        hideLoading()

        // alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )

        if( response.error === false ){
            firebaseLogEvent('sign_up', { method: "Email"})
            // dispatch(loginModalSetHidden(true))
            // window.location.reload()

            // setModalTextTitleSendEmail(response.message.title)
            setModalTextTitleSendEmail("Email sent")
            // setModalTextDetailSendEmail(response.message.message)
            setModalTextDetailSendEmail("We have sent you an email. Please check your email and click the link to verify your email address.")
            setModalButton1SendEmail('OK')
            // setModalButton2SendEmail(response.message.button)
            setModalButton2SendEmail("Resend Email")
            setVerifyEmailToken(response.result.verifyEmailToken)
            setShowModal(true)


        } else {
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
        }

    }

    const handleOnCloseButtonClick = () => {
        handleClose()
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))
    }

    const handleResendEmailClick = () => {
        // verifyEmailToken
        showLoading();
        handleClose()
        sendVerifyEmail(verifyEmailToken).then(handleVerifyResponse)


        // //--- mockup data
        // hideLoading();
        // alert("Please check your email and click a link to verify your email address.")

    }

    const handleVerifyResponse = async (response) => {
        
        hideLoading();
        // handleClose()
        alert(response.message.message)
        // setShowCheckboxAgreeModal(false)
        // dispatch(loginModalSetCurrentTab(''))
        // dispatch(loginModalSetHidden(false))

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if( !validateEmail(username) ) {
            alert("โปรดระบุอีเมลที่ถูกต้อง")
        }else if( !validatePassword(password) ) {
            alert("รหัสผ่านต้องมีทั้งตัวอักษรกับตัวเลข รวมกันอย่างน้อย 8 ตัวอักษรขึ้นไป")
        }else{
            showLoading()
            emailSignUp(username, password).then(handleEmailSignUpResponse)
        }
        
    }

    return (
        <>
        <form className='login-modal-form' hidden={!show} onSubmit={handleOnSubmit}>
            <div className='login-modal-form-header'>
                <span className='login-modal-form-back-button' onClick={() => dispatch(loginModalSetCurrentTab(''))}>Back</span>
                <h3 className='text-center'>Sign Up</h3>
            </div>
            <div className='login-modal-form-body text-center'>
                <div className="form-group mb-2">
                    <input type="text" className="form-control" placeholder="Email" onChange={handleUsernameChange} required/>
                    <input type="password" className="form-control" placeholder="Password" onChange={handlePasswordChange} autoComplete='new-password' required/>
                    <button className='btn login-modal-form-button'>Sign Up</button>   
                </div>
                <TermAndPrivacy />
            </div>
        </form>

        <Modal className='' show={showModal} onHide={handleClose}>
        <div className='modal-content' style={{backgroundColor: 'RGB(63,63,63)', border: '0 none'}}>
        <Modal.Header style={{color: 'white',borderColor: 'rgba(255,255,255, 0.9)'}}>
        {/* <ModalCloseButton onClick={closePopup}/> */}
          <Modal.Title>{modalTextTitleSendEmail}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: 'white'}}>

            {modalTextDetailSendEmail}
            
        </Modal.Body>
        <Modal.Footer >
        {/* <button type="button" class="btn btn-outline-danger">Danger</button> */}
          <Button className='btn btn-outline' style={{color: 'var(--color-orange)', backgroundColor: 'RGB(63,63,63)', borderColor: 'var(--color-orange)', letterSpacing: '0.05em', borderWidth: '1.5px'}} variant="secondary" onClick={handleResendEmailClick}>
          {modalButton2SendEmail}
          </Button>
          <Button variant="primary" style={{backgroundColor: 'var(--color-orange)', borderColor: 'var(--color-orange)', color: 'black', borderWidth: '1.5px'}} onClick={handleOnCloseButtonClick}>
          {modalButton1SendEmail}
          </Button>
        </Modal.Footer>
        </div>
      </Modal>
    
        </>
        )
}

const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpForm)

