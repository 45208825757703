import React, {useEffect, useState, useRef} from 'react'
import VideoPlayer from './VideoPlayer';
import { getTitle, getEpisode, callApiLike, callApiRentalStatus } from "../../api";
import { connect } from 'react-redux'
import { selectMedia, selectEpisode, mediaSetEpisodeList, mediaSetThumbnailUrl, mediaReset, mediaSetDetails, mediaSetCanView, mediaSetIsRentalType, mediaSetIsRented, mediaSetIsCurrentEpisodePremium, loginModalSetCurrentTab, loginModalSetHidden } from '../../actions'
import EpisodeContentList from '../../components/video/EpisodeContentList';
import { useParams } from "react-router-dom";
import Description from '../../components/video/Description';
import Title from '../../components/video/Title';
import LikeAndDisLikeButton from '../../components/video/LikeAndDisLikeButton';
import Rating from '../../components/video/Rating';
import Ads from '../../components/ads/Ads';
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper';
import DummyPage from './DummyPage';
import { Col, Row } from 'react-bootstrap';
import { alertErrorByCode, getDayLeftString, getRentalPriceFromMediaDetail, getRentalProductIdFromMediaDetail, goToEpisode, goToHomePage, hideLoading, isEpisodeValid, isMobileWidth, showLoading } from '../../helper/Helper';
import RentButton from '../../components/video/RentButton';
import AboutFlixerPlus from '../../components/video/AboutFlixerPlus';
import RentalalExpire from '../../components/video/RentalExpire';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import AudioIcon from '../../images/media-detail/audioicon.png'
import SubtitleIcon from '../../images/media-detail/subtitleicon.png'

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const VideoPage = ({ media , dispatch }) => {

    const  {id, episode} = useParams();

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const [sourceChangeCount, setSouceChangeCount] = useState(0);
    const [videoSource, setVideoSource] = useState('');
    const [adsDetails , setAdsDetails] = useState({});
    const [playerAdsList , setPlayerAdsList] = useState([]);

    const [previousMediaId, setPreviousMediaId] = useState(null);
    const [previousEpisode, setPreviousEpisode] = useState(null);

    const previousMediaIdRef = useRef();
    previousMediaIdRef.current = previousMediaId;

    const previousEpisodeRef = useRef();
    previousEpisodeRef.current = previousEpisode;

    const sourceChangeCountRef = useRef();
    sourceChangeCountRef.current = sourceChangeCount;

    const [titleAudios  , setTitleAudios]   = useState([]);
    const [titleSubtitle  , setTitleSubtitle]   = useState([]);

    const [titleAudiosLanguageCode, setTitleAudiosLanguageCode] = useState([]);
    const [titleSubtitlesLanguageCode, setTitleSubtitlesLanguageCode] = useState([]);
    const [titleAudioLanguageName, setTitleAudioLanguageName] = useState('');
    const [titleSubtitleLanguageName, setTitleSubtitleLanguageName] = useState('');

    // เอาไว้แสดงผล About
    const [rentExpire  , setRentExpire]   = useState('')

    const [isAlreadyProcess2c2pPayment  , setIsAlreadyProcess2c2pPayment]   = useState(false)

    const isAlreadyProcess2c2pPaymentRef = useRef();
    isAlreadyProcess2c2pPaymentRef.current = isAlreadyProcess2c2pPayment;

    const isCurrentEpisodePremiumRef = useRef();
    isCurrentEpisodePremiumRef.current = media.isCurrentEpisodePremium;

    const isRentalTypeRef = useRef();
    isRentalTypeRef.current = media.isRentalType;

    const isRentedRef = useRef();
    isRentedRef.current = media.isRented;

    const mediaDetailsRef = useRef();
    mediaDetailsRef.current = media.details;

    const query = useQuery()
    const history = useHistory()

    // สำหรับใช้งานกรณีที่ redirect มาจาก 2c2p
    const getRentalPaymentFinishQueryString = () => {
        if( query.get("rental_payment_finish") ){
            return query.get("rental_payment_finish");
        }else{
            return '';
        }
    }

     // สำหรับใช้งานกรณีที่ redirect มาจาก 2c2p
     const getPaymentCancelQueryString = () => {
        if( query.get("cancel") ){
            return query.get("cancel");
        }else{
            return '';
        }
    }

    useEffect(() => {
        dispatch(selectMedia(id));
        dispatch(selectEpisode(episode));
        getTitle(id).then( (res) => handleTitleResponse(res) );

        if( getRentalPaymentFinishQueryString() === 'true' ){

            // จะทำการล้างค่า URL ออก จากนั้นจะ push history เข้าไปเพิ่ม เพื่อไม่ให้ลูกค้าสามารถกด Back กลับหน้า Payment ได้
            // replace history เนื่องจาก url ที่เรียกมาหลังจาก 2c2p payment จะมี querystring ติดมาด้วยเพื่อนำมาประมวลผล เราไม่ต้องการให้ user เห็นรวมถึงไม่ต้องการให้ user กดกลับมาที่ URL นี้ได้
            history.replace( window.location.pathname , {'flixerWeb': true, "fromPaymentPage": true});
            history.push(window.location.pathname, {'flixerWeb': true, "fromPaymentPage": true});
            history.push(window.location.pathname, {'flixerWeb': true, "fromPaymentPage": true});
            // // Push ค่าสุดท้ายให้ไม่มี state firstPage เพื่อที่เวลาไปหน้าอื่นแล้วจะได้กดกลับมาได้
            history.push(window.location.pathname, {'flixerWeb': true});

            // ถ้าเข้าเคสนี้ึคือจะทำการประมวลผล
            if( getPaymentCancelQueryString() === 'false' && isUserLoggedIn() ){
                showLoading()
            }
    
        }

        return () => {
            dispatch(mediaReset())
        }
    }, [])


    useEffect(() => {

        // กรณีที่เปลี่ยน MediaID
        if( previousMediaIdRef.current !== null && previousMediaIdRef.current !== id ){
            
            // reset source change count เนื่องจากเปลี่ยนหนังแล้ว
            setSouceChangeCount(0)
            dispatch(selectMedia(id));
            
            setVideoSource('')
            dispatch(mediaSetCanView(0))
            dispatch(mediaSetIsCurrentEpisodePremium(false))
            dispatch(selectEpisode(episode));
            processSetEpisodePremium(episode, media.episodeList)
            
            getTitle(id).then( (res) => handleTitleResponse(res) );

           
        // กรณีเปลี่ยน Episode
        }else if( previousEpisodeRef.current !== null && previousEpisodeRef.current !== episode ){

            setVideoSource('')
            dispatch(mediaSetCanView(0))
            dispatch(mediaSetIsCurrentEpisodePremium(false))
            dispatch(selectEpisode(episode));
            processSetEpisodePremium(episode, media.episodeList)
            // ให้ State ด้านบนมีการอัพเดทก่อน
            setTimeout( () => {
                if( media.episodeList.length !== 0 ){
                    if( getIsMediaRentalTypeFromMediaDetail(mediaDetailsRef.current) ){
                        // Handle Case ที่เป็นหนัง Rental
                        processTVODMedia(mediaDetailsRef.current)
                    }else{
                        // กรณีไม่ได้เป็น Rental Type ก็จะตรวจสอบตามสิทธิ Premium
                        getEpisodeProcess(id, episode)
                    }
                }
            },100)

        }

        setPreviousMediaId(id)
        setPreviousEpisode(episode)

    }, [id, episode])

    const getDataMediaDetail = ( response ) => {

        let mediaDetails = response.result.title;

        dispatch(mediaSetDetails(mediaDetails));
    }
    
    const handleTitleResponse = ( response ) => {

        let episodeList = response.result.episodes;

        // กรณีที่กรอก video id ที่ไม่มีอยู่ในระบบ episodeList จะมีค่าเป็น null จะทำการส่งกลับหน้า Home
        if( episodeList === null ){
            goToHomePage(history)
            return;
        }

        let mediaDetails = response.result.title;
        let adsDetails = response.result.ads_data.dfp_web;
        adsDetails = setProperlyAdsSize(adsDetails)

        // console.log(' responseresponse' ,response)

        setTitleAudios(response.result.title.audio)
        setTitleSubtitle(response.result.title.subtitles)

        setTitleAudiosLanguageCode(response.result.title.audio_languaes.languages_code)
        setTitleSubtitlesLanguageCode(response.result.title.subtitle_languaes.languages_code)
        setTitleAudioLanguageName(response.result.title.audio_languaes.language_display)
        setTitleSubtitleLanguageName(response.result.title.subtitle_languaes.language_display)

        // console.log(' audio' ,titleAudios)
        // console.log(' subtitles' ,titleSubtitle)

        dispatch(mediaSetDetails(mediaDetails));
        dispatch(mediaSetThumbnailUrl(mediaDetails.featureimage_url));
        dispatch(mediaSetEpisodeList(episodeList));
        setAdsDetails(adsDetails)

        processSetEpisodePremium(episode, episodeList)


        // กรณีที่ Episode เป็น undefined แสดงว่าเข้ามาหน้า video เฉยๆ (/video/xxx/) เราจะต้องทำการเลือก Episode เป็นอันแรกสุดของ List ให้ user
        // และกรณีที่มี Episode จะตรวจสอบว่า episode ที่เลือกอยู่ มีใน list ที่ได้รับมาจาก API ไหม ถ้าไม่มี ก็จะ reset ให้เป็น episode แรกสุดของ list
        if( episode === undefined || !isEpisodeValid(episode, episodeList) ){
            goToEpisode(history, id, episodeList[0].episode, true, true)
            return;
        }

        if( getIsMediaRentalTypeFromMediaDetail(mediaDetails) ){
            // Handle Case ที่เป็นหนัง Type TVOD
            processTVODMedia(mediaDetails)
        }else{
            // กรณีที่มีความผิดพลาดที่ redirect มาจาก 2c2p payment เป็นหนังผิดเรื่องกับที่ซื้อ จะทำการ hideloading ไม่งั้นจะขึ้น loading ค้าง
            hideLoading()
            // Handle Case ที่ไม่ใช่หนัง TVOD
            getEpisodeProcess(id, episode)
        }
    }


    const getIsMediaRentalTypeFromMediaDetail = (mediaDetails) => {
        return mediaDetails.movie_type === 'tvod' || ( mediaDetails.movie_type === 'tvod,svod' && !isUserPremium() )
    }

    const handle2c2pPayment = (isRented) => {

        setIsAlreadyProcess2c2pPayment(true)

        if( getRentalPaymentFinishQueryString() === 'true' ){

            // กรณีเป็น Cancel จะไม่ทำอะไรต่อ
            if( getPaymentCancelQueryString() === 'true' ){

            }else{
    
                showLoading()
    
                let intervalCount = 0
    
                async function process() {
    
                    intervalCount++
    
                    // Set limit จำนวนครั้งในการเรียกซ้ำ ถ้าเรียกเกินนี้ก็จะหยุดเรียกต่อและถือว่าไม่ผ่านละ
                    if( intervalCount <= 3 ){
    
                        let response = await callApiRentalStatus()

                        // กรณีที่ลูกค้าได้เป็น premium แล้ว
                        if( response.result.rental_status === "validate" ){
                            
                            // ถ้าได้สิทธิ์เช่าเรียบร้อยแล้วเราจะไม่เรียก API ซ้ำ เนื่องจาก API แรกก็ได้ข้อมูลที่ถูกต้องครบอยู่แล้ว
                            if( !isRented ){
                                // อันนี้จะเรียกก็ต่อเมื่อ Payment เข้าหลังบ้านล่าช้าหรืออะไรก็ตามทำให้เรียกครั้งแรกยังไม่ได้สิทธิ์เช่า
                                getTitle(id).then( (res) => handleTitleResponse(res) );
                            }
                 
                            // หน่วงเวลาสำหรับให้ title เรียกข้อมูลมาก่อน
                            setTimeout( () => { 
                                hideLoading() 
                                alert('คุณได้รับสิทธิ์เรียบร้อย\n\n' + window.flixer.aboutFlixerPlusDescription)
                            }, 1000)

                        }else if( response.result.rental_status === "fail" ){
                            hideLoading()
                            alert(response.result.rental_message)
                        }else if( response.result.rental_status === "purchase" ){
                            // จะเรียก API ซ้ำทุกๆ 5 วินาที กรณีเป็น purchase (pending)
                            setTimeout(() =>{ process() }, 5000)
                        }else{
                            hideLoading()
                            alertErrorByCode("500")
                        }
    
                    }else{
                        hideLoading()
                        alertErrorByCode("0003")
                    }
                }
    
                process()
            }
        }

    }

    // จะต้องมีขั้นตอนตรวจสอบก่อนที่จะเรียก API get episode
    const getEpisodeProcess = (id, episodeId) => {

        if( isUserLoggedIn() ){

            if( isCurrentEpisodePremiumRef.current === true ){
                // จะดูว่าถ้า Episode เป็น Premium และ User ก็เป็น Premium ถึงจะเรียก API Episode
                if( isUserPremium() ){
                    getEpisode(id, episodeId).then( (res) => handleEpisodeResponse(res) )
                } else {
                    setIsPageLoaded(true)
                }
            }else{
                // ถ้า Episode ไม่ได้เป็น premium จะเรียก API episode เลย เนื่องจากไม่มีอะไรต้องตรวจสอบ
                getEpisode(id, episodeId).then( (res) => handleEpisodeResponse(res) )
            }
            
        } else {
            setIsPageLoaded(true)
        }

    }

    // สำหรับเช็คและ Set ค่าว่า Episode ปัจจุบันเป็น Premium ไหม
    const processSetEpisodePremium = (episodeId, episodeList) => {

        if( isUserLoggedIn() ){
            
            // ทำการหา Current Episode Detail จาก Episode list 
            let currentEpisodeIndex = episodeList.findIndex((episode) => {
                return episodeId === episode.episode
            })

            if( currentEpisodeIndex !== -1 ){
                let isEpisodePremium = episodeList[currentEpisodeIndex].premium
                dispatch(mediaSetIsCurrentEpisodePremium(isEpisodePremium))
            }
            
        }

    }


    const processTVODMedia = ( mediaDetails ) => {

        dispatch(mediaSetIsRentalType(true))

        if( isUserLoggedIn() ){

            let isRented = false

            updateRentalExpire(mediaDetails)

            // ถ้ามีข้อมูลใน Rental แสดงว่าเคยเช่า
            // ที่ต้องใส่ dispatch(mediaSetIsRented(isRented)) หลายรอบเพราะต้องการให้ค่าทุกอย่างถูกเซ็ทก่อนที่จะเซ็ทค่า IsPageLoaded
            if( Object.keys(mediaDetails.rental).length > 0 ){

                if( mediaDetails.rental.can_view === '1' ){
                    isRented = true
                    dispatch(mediaSetIsRented(isRented))
                    getEpisode(id, episode).then( (res) => handleEpisodeResponse(res) )
                    
                }else{
                    // แสดงปุ่มราคา และกดซื้อได้
                    isRented = false
                    dispatch(mediaSetIsRented(isRented))
                    setIsPageLoaded(true)
                }
            } else {
                dispatch(mediaSetIsRented(isRented))
                setIsPageLoaded(true)
            }

            
            
            // กรณีที่เป็นหนังเช่า แต่ว่า User ยังไม่ได้ทำการเช่าจะไม่สามารถดูหนังได้
            // แต่จะต้องดักว่าถ้าเป็น Trailer จะต้องสามารถดูได้
            if( !isRented && isTrailerEpisode(episode) ){
                getEpisode(id, episode).then( (res) => handleEpisodeResponse(res) )
            }

            // ป้องการเกิดการประมวลผลซ้ำ
            if( !isAlreadyProcess2c2pPaymentRef.current ){
                // มาเริ่มประมวลผลตรงนี้เนื่องจากจะได้ค่า isRent มาแล้ว
                handle2c2pPayment(isRented)
            }
            
        } else {
            setIsPageLoaded(true)
        }

    }


    const handleEpisodeResponse = ( response ) => {
        // This is src for player
        let playlist = response.result.playlist;
        let adsList  = response.result.ads.dfp

        setSouceChangeCount(sourceChangeCount + 1)
        dispatch(mediaSetCanView(response.can_view))
        setPlayerAdsList(adsList)
        setVideoSource(playlist);

        setIsPageLoaded(true)
    }


    const handleLikeDisLikeOnClick = (likeStatus) => {
        if( isUserLoggedIn() ){
            callApiLike(id, likeStatus).then( handleLikeApiResponse )
        } else {
            dispatch(loginModalSetCurrentTab(''))
            dispatch(loginModalSetHidden(false))
        }
    }

    const handleLikeApiResponse = (response) => {

        let userLike = response.result[0].like
        let userDislike = response.result[0].dislike

        getTitle(id).then( (res) => getDataMediaDetail(res) );

        // dispatch(mediaSetDetails({
        //     ...media.mediaDetails,
        //     user_like: userLike,
        //     user_dislike: userDislike
        // }))
    }

    // จะต้อง Set ขนาดของ Ads ให้เป็นตามที่มีบน server และ แบ่งขนาดเป็น 2 ส่วนคือ mobile กับ desktop
    const setProperlyAdsSize = ( adsDetails ) => { 

        if( isMobileWidth() ){
            adsDetails.width  = 300;
            adsDetails.height = 250;
        }else{
            adsDetails.width  = 240;
            adsDetails.height = 400;
        }

        return adsDetails
    }

    // Handle StartWatching จาก Video Player
    const handlePlayerOnStartWatching = () => {
        // ถ้าเป็น Type Rental จะทำการเรียก API /title อีกครั้งหลังจาก startWatching เพื่ออัพเดทวันที่หมดอายุ
        if( media.isRentalType && media.isRented ){
            getTitle(id).then( (response) => {
                let mediaDetails = response.result.title;
                updateRentalExpire(mediaDetails)
            });
        }
    }

    const updateRentalExpire = (mediaDetails) => {

        if( Object.keys(mediaDetails.rental).length > 0 ){

            if( mediaDetails.rental.can_view === '1' ){

                let dayLeftInString = "-"

                if( mediaDetails.rental.watch_start !== "" && mediaDetails.rental.watch_end !== "" ){
                    dayLeftInString = getDayLeftString(mediaDetails.rental.watch_end)
                }else if( mediaDetails.rental.expire_datetime !== "" ){
                    dayLeftInString = getDayLeftString(mediaDetails.rental.expire_datetime)
                }
                setRentExpire(dayLeftInString)

            }

        }
    }


    const isTrailerEpisode = (episode) => {
        return episode === '99'
    }


    return (
        <div className='video-page'>
            <div className='video-container row'>
                { 
                    ( media.canView === 1 || media.canView === '1' ) && isUserLoggedIn()
                    ? 
                        <React.Fragment>
                            <Col xs={12} md={9}>
                                {       
                                        videoSource 
                                    ? 
                                        <VideoPlayer source={videoSource} adsList={playerAdsList} autoPlay={sourceChangeCount > 1} onStartWatching={handlePlayerOnStartWatching}/>  
                                    : 
                                        <div className='video-player-container-sample'>	
                                        </div>
                                }
                            </Col>
                            <Col xs={12} md={3}>
                                <Ads 
                                    adUnitId={adsDetails.ads_unit_id}
                                    sampleImage={adsDetails.banner_url}
                                    width={parseInt(adsDetails.width)}
                                    height={parseInt(adsDetails.height)}
                                />
                            </Col>
                        </React.Fragment>
                    :
                        // กรณีที่ไม่มี permission ในการดู หรือยังไม่ได้ Login
                        <DummyPage 
                            thumbnailUrl={media.thumbnailUrl} 
                            isGuest={!isUserLoggedIn()}
                            adsDetails={adsDetails}
                            isCurrentEpisodePremium={media.isCurrentEpisodePremium}
                            mediaDetails={media.details}
                            showRentButton={media.isRentalType && !media.isRented} 
                            isVideoPageLoaded={isPageLoaded}/>
                }
                
                <div className='w-100'></div>
                <Col xs={12} md={9}>
                    <EpisodeContentList list={media.episodeList}/>
                </Col>
            </div>
            <Row>
                <Col xs={12} md={9} className='video-page-media-details-container'>
                    <Col className='my-4'>
                        <div className='d-flex align-items-start'>
                            <Title text={media.details.name}/>
                        </div>
                    </Col>
                    <LikeAndDisLikeButton likeCount={media.details.user_like} dislikeCount={media.details.user_dislike} likeStatus={media.details.like_status} handleOnClick={handleLikeDisLikeOnClick} />
                    <Row>
                        {/* <Col style={{marginBottom : "22px" }} > */}
                        <Col xs={8} md={4} style={{marginTop : "2px", display: "flex"}} >
                            { (media.details.rating != null) ?
                                <Rating text={ (media.details.rating != null) ? media.details.rating : '' }/>  
                                : ''
                            }
                            {/* <font style={{marginLeft: "15px"}}>{ (media.details.release_year != null) ? media.details.release_year : '' }</font> */}

                            { (titleAudiosLanguageCode != null && titleAudiosLanguageCode[0] !== "")  ? <img className='icon-subtitle' src={SubtitleIcon} alt=''/> : '' }
                            { (titleAudiosLanguageCode != null && titleAudiosLanguageCode[0] !== "")  ?
                                    (titleAudiosLanguageCode.length <= 2) ?
                                        titleAudiosLanguageCode.map( (item, index) => {     
                                            return  <div className='icon-language' key={index}>{item}</div>;
                                        })
                                       
                                    : 
                                        titleAudiosLanguageCode.slice(0, 1).map( (item) => {     
                                            return  <div className='icon-language' key={item}>{item}</div>;
                                        })
                                : ''
                            }
                            {
                                (titleAudiosLanguageCode.length > 2) ?
                                    <div className='icon-language'>...</div>
                                : ''
                            }
                            
                            { (titleSubtitlesLanguageCode != null && titleSubtitlesLanguageCode[0] !== "")  ? <img className='icon-audio' src={AudioIcon} alt=''/> : '' }
                            { (titleSubtitlesLanguageCode != null && titleSubtitlesLanguageCode[0] !== "")  ?
                                    (titleSubtitlesLanguageCode.length <= 2) ?
                                        titleSubtitlesLanguageCode.map( (item, index) => {     
                                            return  <div className='icon-language' key={index}>{item}</div>;
                                        })
                                       
                                    : 
                                        titleSubtitlesLanguageCode.slice(0, 1).map( (item) => {     
                                            return  <div className='icon-language' key={item}>{item}</div>;
                                        })
                                : ''
                            }
                            {
                                (titleSubtitlesLanguageCode.length > 2) ?
                                    <div className='icon-language'>...</div>
                                : ''
                            }
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col style={{textAlign : "left" ,lineHeight : "14px", verticalAlign: 'middle', marginBottom: '20px'}} xs={12} md={8}>
                            {/* <label className='' style={{marginRight : "5px", color: "gray",fontSize: "12px"}}>{ (media.details.copyright != null) ? media.details.copyright : '' }</label> */}
                                <label className='' style={{marginRight : "5px", color: "gray",fontSize: "12px",paddingTop: '8px',marginBottom: '0px'}}>{ (media.details.copyright != null) ? media.details.copyright : '' }</label>

                        </Col>
                        <Col md={4}>
                            { media.isRentalType && media.isRented ? <RentalalExpire expireInDayString={rentExpire} /> : '' }
                            { media.isRentalType && !media.isRented ? <RentButton productId={getRentalProductIdFromMediaDetail(media.details)} price={getRentalPriceFromMediaDetail(media.details)} className='desktop-mode' /> : '' }
                        </Col>
                    </Row>
                    { media.isRentalType && !media.isRented ? <RentButton productId={getRentalProductIdFromMediaDetail(media.details)} price={getRentalPriceFromMediaDetail(media.details)} className='mb-3 mobile-mode' /> : '' }
                    <Description text={media.details.description} subtitles={titleSubtitleLanguageName} audios={titleAudioLanguageName}/>
                    { media.isRentalType ? <AboutFlixerPlus /> : '' }
                </Col>
            </Row>
        </div>
    );
}


const mapStateToProps = (state, ownProps) => ({
    media: state.media
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoPage)
