import React from 'react'
import {Row, Col} from 'react-bootstrap'

const TermAndPrivacy = () => {
    return (
        <Row className='login-modal-term-privacy'>
            <Col>
                By signing up, you agree to our <a target='_blank' href='/term-of-use'>Terms of Use</a> and <a target='_blank' href='/privacy-policy'>Privacy Policy</a>
            </Col>
        </Row>
    )
}

export default TermAndPrivacy
