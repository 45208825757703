import React from 'react'
import AppleSignin from 'react-apple-signin-auth';
import {FaApple} from 'react-icons/fa'
import { appleLogin } from '../../api';
import { consoleErrorHelper, firebaseLogEvent, hideLoading, showLoading } from '../../helper/Helper';

const AppleLoginButton = () => {

    var redirectUrl
    if( process.env.REACT_APP_ENV === 'production' ){
        redirectUrl = 'https://www.flixerapp.com'
    }else if( process.env.REACT_APP_ENV === 'development' ){
        redirectUrl = 'https://dev.flixerapp.com'
    }else{
        redirectUrl = 'https://www.flixerapp.com'
    }

    const onSuccess = ( response ) => {

        let email = ''
        let fullName = '';

        // จะมีส่งมาแค่กรณี login ครั้งแรกเท่านั้น
        if( typeof response.user !== 'undefined' ){
            email = response.user.email
            fullName = response.user.name.firstName + " " + response.user.name.lastName
        }

        let appleData = {
            appleJWT: response.authorization.id_token,
            email: email,
            name: fullName
        }

        showLoading()
        appleLogin(appleData).then(handleAppleLoginResponse)
        
    }

    const handleAppleLoginResponse = async (response) => {

        hideLoading()

        if( response.error === false ){
            firebaseLogEvent('login', { method: "Apple"})
            window.location.reload()
        }else{
            alert("Title: " + response.message.title + "\nMessage: " + response.message.message + "\n" )
        }

    }


    
    return (
        <AppleSignin
            authOptions={{
                clientId: 'com.flixer.flixer.website',
                scope: 'email name',
                redirectURI: redirectUrl,
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
            }}
            onSuccess={onSuccess}
            onError={(error) => consoleErrorHelper(error)}
            render={(props) => <button {...props} className='signin-with-apple-button'><FaApple fill='black'/>Sign in with Apple</button>}
        />
    )
}

export default AppleLoginButton
