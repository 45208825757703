import React from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { goToCategoryContent } from '../../helper/Helper'

const MenuNavButtonChildren = () => {

    const siteCurrentPage = useSelector(state => state.site.currentPage)
    const history = useHistory();

    const goToChildrenMenu = () => {
        goToCategoryContent(history, "children")
    }

    return (
        <div className={ siteCurrentPage === 'children' ? 'nav-bar-text-menu-button active' : 'nav-bar-text-menu-button'} onClick={goToChildrenMenu}>
            <div className='nav-bar-text-menu-button-name'>
            Kids & Family
            </div>
        </div>
    )
}

export default MenuNavButtonChildren
