import React from 'react'
import { useHistory } from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import { goToEpisode } from '../../helper/Helper'
// import { chromecastSetMediaId, chromecastSetEpisode, mediaSetStartTime, siteSetBeforeTitleVideo } from '../../actions'
import {
    chromecastSetIsPlay,
    mediaSetCurrentTime,
    mediaSetStartTime, 
    siteSetBeforeTitleVideo,
    chromecastSetMediaId, 
    selectMedia,
    selectEpisode
    } from '../../actions'

const ContinueWatchingContent = ({details}) => {

    const history = useHistory()

    const dispatch = useDispatch()
    const chromeCastSessionState = useSelector(state => state.chromecast.sessionState)

    const handleOnClick = () => {
        dispatch(siteSetBeforeTitleVideo(String(details.title)));
        dispatch(mediaSetStartTime(details.start_time))
        // goToEpisode(history, details.title, details.episode)


        // กรณีที่เปิด ChromeCast อยู่ จะทำการเล่นหนังผ่าน ChromeCast
        if( chromeCastSessionState === window.flixer.chromecast.sessionState.started || 
            chromeCastSessionState === window.flixer.chromecast.sessionState.resumed
        ){

            dispatch(selectMedia(details.title));
            dispatch(selectEpisode(details.episode));
            dispatch(mediaSetStartTime(details.start_time));
            dispatch(mediaSetCurrentTime(details.start_time));
            dispatch(chromecastSetIsPlay(true));
            dispatch(chromecastSetMediaId(details.title))

            
        }else{
        goToEpisode(history, details.title, details.episode)
        }
    }

    const calculateProgressPercent = () => {
        return (details.start_time/details.duration) * 100
    }

    return (
        <div className='continue-watching-content' onClick={handleOnClick}>
            <div>
                <div className='continue-watching-content-thumbnail'>
                    <img src={details.thumbnail_url} alt='' />
                </div>
                <div className='continue-watching-content-progress-background'>
                </div>
                <div style={{"width": calculateProgressPercent() + "%"}} className='continue-watching-content-progress'>
                </div>
            </div>
            <div>
                <div className='continue-watching-content-display-episode'>
                    {details.episode_title_display}
                </div>
                <div className='continue-watching-content-display-title'>
                    {details.title_display}
                </div>
            </div>
        </div>
    )
}

export default ContinueWatchingContent