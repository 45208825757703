import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { getCategoryFromSlug } from '../../api'
import { makeListSupportAllMediaContentListFormat } from '../../helper/Helper'
import AllMediaContentList from '../global/AllMediaContentList'
import {Helmet} from "react-helmet";

const CategoryPage = () => {

    const {slug} = useParams();

    const [description, setDescription] = useState('');

    
    
    const [currentCategoryContentList, setCurrentCategoryContentList] = useState([])
    const [currentCategoryTitle, setCurrentCategoryTitle] = useState('')

    useEffect(() => { 
        getCategoryFromSlug(slug).then( (res) => {
            handleGetCategoryContentListResponse(res)
        })
    }, [slug])

    const handleGetCategoryContentListResponse = ( response ) => {
        let list = makeListSupportAllMediaContentListFormat(response.result.data, 'category');
        setCurrentCategoryContentList(list)
        setCurrentCategoryTitle(response.result.title)
        // document.title = response.result.title
    }

    useEffect(() => {
        if(slug === 'idol') {
            setDescription('ศูนย์รวมอนิเมะไอดอลหลากหลายซีซั่น')
        } else if(slug === 'gundam') {
            setDescription('รวมกันดั้มหาดูยากทุกตอน')
        } else if(slug === 'masked rider') {
            setDescription('แหล่งรวมมาสค์ไรเดอร์ครบทุกตอน')
        } else if(slug === 'movie') {
            setDescription('ดูภาพยนตร์ญี่ปุ่นชนโรงก่อนใคร')
        } else {
            setDescription('')
        }
    },[slug])

    return (
        <div>
            <Helmet>
                <title>{currentCategoryTitle}</title>
                {(description !== '') ? <meta name="description" content={description} /> : '' }
            </Helmet>
            <AllMediaContentList title={currentCategoryTitle} list={currentCategoryContentList } description={description}/>
        </div>
    )
}

export default CategoryPage
