import React from 'react'
import ButtonBadgeGold from '../../images/premium-modal/button-badge-gold.png'
import ButtonBadgeSilver from '../../images/premium-modal/button-badge-silver.png'

const PrimaryButton = ({firstLine, secondLine, thirdLine, bgColor, badgePlus, onClick = () => {}}) => {
    return (
        <button className='premium-modal-primary-button' onClick={onClick} style={{fontSize: '14px', backgroundColor: bgColor, borderColor: bgColor, height: '65px'}}>
            {firstLine}<br/>
            {secondLine}<br/>
            {thirdLine}
            { (badgePlus === true) ?
                <img className='premium-modal-secondary-button-badge' src={ButtonBadgeGold} alt='' />
                :
                <img className='premium-modal-secondary-button-badge' src={ButtonBadgeSilver} alt='' />
            }
        </button>
    )
}

export default PrimaryButton
