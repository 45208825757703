import React, {useEffect} from 'react'
import {getRental} from '../../api';
import {connect} from 'react-redux'
import {homeSetShelf, homeSetSlideshow} from '../../actions'
import Ads from '../../components/ads/Ads';
import MediaBannerContentList from '../../components/home/MediaBannerContentList';
import SlideshowContentList from '../../components/home/SlideshowContentList';
import MediaListContainer from '../../components/home/MediaListContainer';

const FlixerPlusPage = ({shelf, slideshow,setShelf,setSlideshow}) => {


    useEffect(() => {
		getRental().then( (res) => { processApi(res) } );
	}, []);

    const processApi = function(response){
        setShelf(response.result.shelf);
        setSlideshow(response.result.slideshow);
    }

    return (
        <div className='home-page flixer-plus-page'>
            <SlideshowContentList list={slideshow} />
            {
                shelf 
                ?
                shelf.map( ( shelfContent, index ) => {
                    if( shelfContent.type === 'movie'){
                        if( shelfContent.data.length > 0 ){
                            return <MediaListContainer key={index} title={shelfContent.title} lists={shelfContent.data} shelfId={shelfContent.id} />;
                        }else{
                            return '';
                        }
                    }else if( shelfContent.type === 'banner2' ){
                        return <MediaBannerContentList key={index} list={shelfContent.data}/>;
                    }else if( shelfContent.type === 'ads' ){
                        return  <Ads 
                                    key={index} 
                                    adUnitId={shelfContent.ads_data.dfp.ads_unit_id} 
                                    sampleImage={shelfContent.ads_data.dfp.banner_url} 
                                    width={parseInt(shelfContent.ads_data.dfp.width)} 
                                    height={parseInt(shelfContent.ads_data.dfp.height)}
                                />
                    }else{
                        return "";
                    }
                })
                :
                ""
            }
        </div>
    );
}


const mapStateToProps = (state, ownProps) => ({
    shelf: state.home.shelf,
    slideshow: state.home.slideshow
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    setShelf: (shelf) => dispatch(homeSetShelf(shelf)) ,
    setSlideshow: (slideshow) => dispatch(homeSetSlideshow(slideshow)) 
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlixerPlusPage)
