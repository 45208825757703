import {callAISGetAPI, callGet,callPost,callPostWithoutData} from '../helper/ApiHelper'

let apiVersion;
let ebookapiUrl;

// if (process.env.REACT_APP_ENV === 'production'){
//     apiVersion = '1.12'
//     ebookapiUrl = 'https://ebookapi.flixerapp.com/V1'
// }else{
//     apiVersion = '1.13'
//     // apiVersion = '1.12' // 1.12 is prod ชั่วคราว
//     ebookapiUrl = 'https://ebookapidev.flixerapp.com/V1'
// }

    apiVersion = 'v1'
    ebookapiUrl = 'https://dev4.flixerapp.com/' + apiVersion;

let flixerApiPrefixUrl = 'https://dev1.flixerapp.com/' + apiVersion;

export async function getWoocommercePermalink( productId ){
    // productId = "1318";
    return callGet(ebookapiUrl +'/getWoocommerceProductPermalink/'+productId)
}

export async function getEpisodeList( mediaId ){
    return callGet(flixerApiPrefixUrl +'/episodeList/'+mediaId)
}

export async function getEpisode( mediaId, episode ){
    return callGet(flixerApiPrefixUrl +'/episode/'+mediaId+'/'+episode)
}

export async function getTitle( mediaId ){
    return callGet(flixerApiPrefixUrl +'/title/'+mediaId)
}

export async function getSearchList( searchStr ){
    return callGet(flixerApiPrefixUrl +'/search/'+searchStr)
}

export async function getHomePage(){
    return callGet(flixerApiPrefixUrl +'/home')
}

export async function getHomeAllPage(){
    return callGet(flixerApiPrefixUrl +'/homeAll')
}

export async function getContinueWatching(){
    return callPostWithoutData(flixerApiPrefixUrl +'/contwatch')
}

export async function getRental(){
    return callGet(flixerApiPrefixUrl +'/rental')
}

export async function getCategoryList(){
    return callGet(flixerApiPrefixUrl +'/categories')
}

export async function getCategoryFromSlug(slug){
    // slug = slug.replace(" ", "_");
    slug = slug.replace(/ /g, '_');
    return callGet(flixerApiPrefixUrl +'/webCategory/'+encodeURI(slug))
}

export async function getCharacterList(id){
    return callGet(flixerApiPrefixUrl +'/webCharacter/' + id )
}

export async function getShelf(id){
    return callGet(flixerApiPrefixUrl +'/webShelf/' + id )
}

export async function guestLogin(){
    return callPost(flixerApiPrefixUrl +'/guestlogin' , { 'device_id': "web" })
}

export async function refreshToken(){
    return callGet(flixerApiPrefixUrl +'/refreshToken')
}

export async function getProfile(){
    return callGet(flixerApiPrefixUrl +'/profile')
}

export async function logout(){
    return callPostWithoutData(flixerApiPrefixUrl +'/logout')
}


export async function profileUpload( base64Image ){
    return callPost(flixerApiPrefixUrl +'/profileUpload', {data: base64Image} )
}


export async function startWatching( mediaId, episode ){
    return callPostWithoutData(flixerApiPrefixUrl +'/startWatching/' + mediaId + '/' + episode)
}

export async function watching( mediaId, episode, currentTime ){
    if( currentTime <= 0 ) return // จะไม่เรียก API กรณีที่ currentTime น้อยกว่าหรือเท่ากับ 0
    return callPostWithoutData(flixerApiPrefixUrl +'/watching/' + mediaId + '/' + episode + '/' + currentTime)
}

export async function endWatching(){
    return callPostWithoutData(flixerApiPrefixUrl +'/endWatching')
}

export async function callApiLike( mediaId, likeStatus ){
    return callPost(flixerApiPrefixUrl +'/like/'+ mediaId , {like_status: likeStatus} )
}

export async function callAISRequestOTPApi( mobileNumber ){
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=generateThirdPartyOTP&mobile='+ mobileNumber + '&platform=web&token=')
}

export async function callAISConfirmOTPApi(mobileNumber, otp){
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=loginThirdPartyOTP&mobile='+mobileNumber+'&otp='+otp+'&platform=web&token=')
}

export async function callAISPackageListApi(mobileNumber) {
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=packageList&mobile='+mobileNumber+'&platform=web&token=')
}

export async function callApiRedeem(code){
    return callPost(flixerApiPrefixUrl +'/redeem' , {redeem_code: code} )
}

export async function callApiRentalList(){
    return callGet(flixerApiPrefixUrl +'/rentalList')
}

export async function callApiSubscriptionList(){
    return callGet(flixerApiPrefixUrl + '/subscriptionList')
}

export async function callApiSubscriptionPurchase(productId, price){
    return callPost(flixerApiPrefixUrl + '/subscriptionPurchase', { product_id: productId, price: price })
}

export async function callApiSubscriptionStatus(){
    return callGet(flixerApiPrefixUrl + '/subscriptionStatus')
}

export async function callApiTopupStatus(){
    return callGet(flixerApiPrefixUrl + '/topupStatus')
}

export async function callApiCancelSubscription(){
    return callGet(flixerApiPrefixUrl + '/subscriptionCancel')
}

export async function callApiRentalBuy( productId, price ){
    return callPost(flixerApiPrefixUrl + '/rentalBuy', { product_id: productId, price: price })
}

export async function callApiRentalStatus(){
    return callGet(flixerApiPrefixUrl + '/rentalStatus')
}


export async function emailLogin(username, password){

    return callPost(flixerApiPrefixUrl +'/emaillogin2', {
        email: username,
        password: password, 
        devicie_id: 'web',
        screen_density: 'xxhdpi',
        mac_address: ''
    })
}


export function emailSignUp(username, password){

    return callPost(flixerApiPrefixUrl +'/emailregister2', {
        email: username,
        password: password, 
        mac_address: ''
    })
}


export function sendVerifyEmail(tokenVerify){

    return callPost(flixerApiPrefixUrl +'/sendVerifyAccountEmailWithoutLogin', {
        verifyEmailToken: tokenVerify,
    })
}

// export function fbLogin(fbData){
//     return callPost(flixerApiPrefixUrl +'/fblogin', {
//         ...fbData, 
//         device_id: 'web',
//         mac_address: ''
//     })
// }

export function fbLogin(fbData){
    return callPost(flixerApiPrefixUrl +'/fblogin2', {
        ...fbData, 
        device_id: 'web',
        mac_address: ''
    })
}


export function appleLogin(appleData){
    return callPost(flixerApiPrefixUrl +'/webAppleLogin', {
        ...appleData, 
        device_id: 'web'
    })
}

export function forgetPassword(email){
    return callPost(flixerApiPrefixUrl +'/forgot', {
        email: email
    })
}

export async function receiptValidator(){
    return callGet(flixerApiPrefixUrl +'/receiptValidator', {
        receipt_data: '',
        platform: 'web',
        end_point: 'production',
        package_name: '',
        product_id: '',
        purchase_token: ''
    })
}


export async function checkWebCredentialExist(){
    return callGet(flixerApiPrefixUrl +'/checkWebCredentialExist')
}

export async function changePassword(){
    return callPost(flixerApiPrefixUrl +'/changepassword', {device_id: 'web'})
}

export async function sendBeaconEndWatch(mediaId, episode){
    navigator.sendBeacon(flixerApiPrefixUrl + '/endWatch/'+mediaId+'/'+episode)
}

export async function checkMaintenance(){
    return callGet(flixerApiPrefixUrl +'/maintenance')
}

export async function callApiTopupList(){
    return callGet(flixerApiPrefixUrl +'/topupList')
}

export async function callApiTopupBuy(productId, price){
    return callPost(flixerApiPrefixUrl +'/topupBuy', {
        product_id: productId,
        price: price
        // product_id: 'com.flixer.flixer.topup30',
        // price: 79
    })
}

export async function sendChangePasswordEmail(){
    return callGet(flixerApiPrefixUrl +'/changepassword')
}

export async function removeAccount(){
    return callGet(flixerApiPrefixUrl +'/removeAccount')
}

export async function getVerifyAccountStatus() {
    return callGet(flixerApiPrefixUrl +'/getVerifyAccountStatus')
}

export async function sendVerifyAccountEmail() {
    return callGet(flixerApiPrefixUrl +'/sendVerifyAccountEmail')
}
