import React, { useEffect, useState } from 'react';
import {Suspense} from 'react';
import {FaEnvelope} from 'react-icons/fa';
import Icon from '../../images/login-modal/icon/icon_mail1.png';
import { useTranslation } from 'react-i18next';


const SignInWithEmailButton = () => {


    const { t , i18n} = useTranslation('trans');

    return (
        <Suspense fallback={<div>Loading...</div>}>
                <div className='btn ' id="dropdown" style={{color: "black", background: "white" , borderRadius:"5px", borderColor: "white", paddingLeft: "10px", paddingRight: "10px", paddingTop: "8px", paddingBottom: "8px", fontSize: "16px", width: "100%", marginBottom:"-6px"}}><FaEnvelope fill='white'/>  
                <img className='' src={Icon} alt='' style={{width: "20px" , marginRight: "5px", marginBottom: "2px"}}/> 
                <label>Sign in with Email</label>
                </div>
        </Suspense>
    )
}

export default SignInWithEmailButton
